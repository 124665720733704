import isMobile from "../lib/is_mobile";
import { findPlacingCharacterId, findPlacingImageId, getNextPlacingCharacterElement, getNextPlacingImageElement, findPlacingShapeId, getNextPlacingShapeElement } from "./board/board_place_token_and_image";

var cursor;

function setIconToAlertImage(alertElement, cursorId) {
  if (alertElement != null) { 
    const alertImage = alertElement.closest('.game-alert').find('img');
    const cursorImage = $(`[data-cursor-id=${cursorId}] img`);
    if (alertImage.length > 0 && cursorImage.length > 0) {
      cursorImage.attr('src', alertImage.attr('src'));
    }
  }
  $(`[data-cursor-id=${cursorId}]`).removeClass('d-none');
}

function setIconToAlertShape(alertElement) {
  if (alertElement != null) { 
    const alertSvg = alertElement.closest('.game-alert').find('svg');
    const cursorShapeDiv = $(`[data-cursor-id='tool-place-shape']`);
    if (alertSvg.length > 0 && cursorShapeDiv.length > 0) {
      cursorShapeDiv.html(alertSvg[0].outerHTML);
    }
  }
  $(`[data-cursor-id='tool-place-shape']`).removeClass('d-none');
}

export function updateCursorIcon(cursorId = null) {
  $(`[data-cursor-id]`).addClass('d-none');
  const characterId = findPlacingCharacterId();
  const imageId = findPlacingImageId();
  const shapeId = findPlacingShapeId();
  if (characterId != null) {
    setIconToAlertImage(getNextPlacingCharacterElement(), 'tool-place-token');
  } else if (imageId != null) {
    setIconToAlertImage(getNextPlacingImageElement(), 'tool-place-image');
  } else if (shapeId != null) {
    setIconToAlertShape(getNextPlacingShapeElement());
  } else {
    if(cursorId == null) {
      cursorId = $('.tool-select .action.selected').data('toolId');
    }
    $(`[data-cursor-id=${cursorId}]`).removeClass('d-none');
  }
}

export function updateCursorElementToMousePosition(e) {
  if(isMobile()) return;
  cursor.css({
    top: e.clientY - cursor.height() / 2,
    left: e.clientX - cursor.width() / 2,
    opacity: 1,
  });  
}

$( document ).ready(function() {
  cursor = $('#cursor-indicator');

  $('.board-wrapper').mousemove(function(e) {
    updateCursorElementToMousePosition(e);
  });
  
  $('.board-wrapper').mouseleave(function() {
    cursor.css({
      opacity: "0"
    });
  }).mouseenter(function() {
    cursor.css({
      opacity: "1"
    });
  }).mousedown(function() {
    cursor.css({
      transform: "scale(.8)"
    });
  }).mouseup(function() {
    cursor.css({
      transform: "scale(1)"
    });
  });
});

$(document).on('mouseup', '.update-cursor-location-menu .dropdown-item', function (e) {
  if(window.__board != null && window.__board.boardId != null) {
    updateCursorElementToMousePosition(e);
  }
});
