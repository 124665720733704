
var board;

var base = fabric.Canvas.prototype;

$( document ).on('board:created', function(event, b) { 
  board = b;
  setDebugFlags();
});

const drawDebugWall = function(x1, y1, x2, y2) {
  board.addObject(new fabric.Line([x1, y1, x2, y2],{
    stroke: "blue",
    strokeWidth: 1,
    hasControls: false,
    hasBorders: false,
    selectable: false,
    evented: false,
    lockMovementX: true,
    lockMovementY: true,
    debug: true,
    debugWall: true
  }))
}

base.updateDebugWalls = function() {
  if (!board.debugWalls) { return }

  board.getObjects().filter(element => element.debugWall == true ).forEach(wall => board.remove(wall))
  Object.keys(board.horizontalWalls).forEach(function(wall) {
    var x = wall.split(",")[0] * board.wallBlockSize;
    var y = wall.split(",")[1] * board.wallBlockSize;
    drawDebugWall(x, y, x + board.wallBlockSize, y);
  })

  Object.keys(board.verticalWalls).forEach(function(wall) {
    var x = wall.split(",")[0] * board.wallBlockSize;
    var y = wall.split(",")[1] * board.wallBlockSize;
    drawDebugWall(x, y, x, y + board.wallBlockSize);
  })

}

const createDebugVisibilityStartPoint = function(startPoint) {
  return new fabric.Circle({
    left: startPoint.x,
    top: startPoint.y,
    radius: 5,
    fill: 'green',
    originX: 'center',
    originY: 'center',
    selectable: false,
    debug: true,
    debugVisibility: true
  })  
}

const drawDebugVisibilityPolygon = function(endPoints) {
  var polygon = new fabric.Polygon(endPoints, {
    strokeWidth: 3,
    selectable: false,
    evented: false,
    debug: true,
    debugVisibility: true,
    debugVisibilitySinglePoint: true,
    stroke: "orange",
    fill: 'transparent',
  })
  board.addObject(polygon);
}

const drawDebugVisibilityIntersectionPoints = function(endPoints) {
  endPoints.forEach(function(point) {
    var intersectionPoint = new fabric.Circle({
      left: point.x,
      top: point.y,
      radius: 6,
      fill: 'green',
      originX: 'center',
      originY: 'center',
      selectable: false,
      evented: false,
      debug: true,
      debugVisibility: true,
      debugVisibilitySinglePoint: true,
      });
    board.addObject(intersectionPoint);
  })
}

const drawDebugVisibilitySightLines = function(sightLines) {
  sightLines.forEach(function(line) {
    var sightLine = new fabric.Line([line.x1, line.y1, line.x2, line.y2], {
      stroke: "pink",
      strokeWidth: 2,
      selectable: false,
      evented: false,
      debug: true,
      debugVisibility: true,
      debugVisibilitySinglePoint: true,
    })
    board.addObject(sightLine);
  })
}

base.clearDebugVisibility = function() {
  if (!board.debugVisibility) { return }
  board.getObjects().filter(element => element.debugVisibility == true ).forEach(object => board.remove(object))
}

base.updateDebugVisibility = function(startPoint, endPoints, sightLines) {
  if (!board.debugVisibility) { return }

  var point = createDebugVisibilityStartPoint(startPoint);

  point.on("mousedown", function(options) {
    board.getObjects().filter(element => element.debugVisibilitySinglePoint == true ).forEach(object => board.remove(object))
    drawDebugVisibilityPolygon(endPoints)
    drawDebugVisibilityIntersectionPoints(endPoints)
    drawDebugVisibilitySightLines(sightLines)
  })
  board.addObject(point);
}

const setDebugFlags = function() {
  if(window.location.href.includes('debugWalls')) { board.debugWalls = true }
  if(window.location.href.includes('debugVisibility')) { board.debugVisibility = true }
}