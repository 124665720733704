function updateTheme() {
  if(document.documentElement.getAttribute('data-user-set-theme') === 'true') { return }
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.documentElement.setAttribute('data-bs-theme', 'dark');
  } else {
      document.documentElement.setAttribute('data-bs-theme', 'light');
  }
}

// Initial check
updateTheme();

// Listen for changes in color scheme preference
const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

// some very old versions of safari don't support addEventListener
if (mediaQuery?.addEventListener) {
  mediaQuery.addEventListener('change', updateTheme);
} else {
  mediaQuery.addListener(updateTheme);
}
