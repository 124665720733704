import { setAllOpacityAndSelectability } from "./board_object_opacity_and_selectability";

var board;
$( document ).on('board:created', function(event, b) { board = b });

export function toggleDisplayOption(displayOptionName) {
  var displayOptionComponent = $(`.display-option[data-display-option-name=${displayOptionName}]`);
  var checkbox = displayOptionComponent.find('input[type=checkbox]');
  if(checkbox.prop('checked') == true) {
    checkbox.prop('checked', false)
    displayOptionComponent.find('.display-option-checked').collapse('hide');
    displayOptionComponent.find('.display-option-unchecked').collapse('show');
    displayOptionComponent.find('.display-option-label').addClass(['text-muted'])
    board[displayOptionName] = false
    if (displayOptionComponent.data('reset') == 'vision') {
      $('#gm-vision-button-wrapper').addClass('d-none');
    }
  } else {
    checkbox.prop('checked', true)
    displayOptionComponent.find('.display-option-unchecked').collapse('hide');
    displayOptionComponent.find('.display-option-checked').collapse('show');
    displayOptionComponent.find('.display-option-label').removeClass(['text-muted'])
    board[displayOptionName] = true
    if (displayOptionComponent.data('reset') == 'vision') {
      $('#gm-vision-button-wrapper').removeClass('d-none');
    }
  }
  if(displayOptionComponent.data('reset') == 'visibleObjects') {
    setAllOpacityAndSelectability(board);
  } else if (displayOptionComponent.data('reset') == 'vision') {
    board.updateVisibility();
  }
}

$(document).on('click','.display-option',function(event){
  var displayOptionName = $(this).data('displayOptionName');
  toggleDisplayOption(displayOptionName);
}); 
