import * as requests from "./board_requests"
import uniqueId from "../../lib/unique_id"
import { setObjectOpacityAndSelectability } from "./board_object_opacity_and_selectability";

var board;
var base = fabric.Canvas.prototype;

$( document ).on('board:created', function(event, b) { board = b });

export const addPin = function(options) {
  var pinData = { type: "pin", top: Math.round(options.absolutePointer.y), left: Math.round(options.absolutePointer.x), uid: uniqueId(), pinColor: board.pinColorDefault, pinSize: board.pinSizeDefault, showPlayers: board.pinShowPlayersDefault}
  var pinObject = board.addBackendObject(pinData);
  requests.createRequest(pinObject);

  board.renderAll();
}

base.handleAddPinOnMouseUp = function(options) {
  if(!board.isPinningMode) { return }
  if(options.target != null && (options.target.pin != null) ) { return }

  addPin(options)
  board.mouseUpPerformed = true;
}

base.convertServerPinToClient = function(pinData) {
  var path="M192 512s192-208 192-320C384 86 298 0 192 0S0 86 0 192C0 304 192 512 192 512zm0-384a64 64 0 1 1 0 128 64 64 0 1 1 0-128z";

  var newObject = new fabric.Path(path, {
    originX:'center', 
    originY:'bottom', 
    top: pinData.top, 
    left: pinData.left, 
    pin: true, 
    uid: pinData.uid, 
    fill: pinData.pinColor,
    pinColor: pinData.pinColor,
    showPlayers: pinData.showPlayers,
    pinSize: pinData.pinSize,
  });
  if(newObject.pinSize == 'large') {
    newObject.scale(0.15);
  } else if (newObject.pinSize == 'small') {
    newObject.scale(0.05);
  } else {
    newObject.scale(0.1);
  }
  newObject.hasControls = false;
  newObject.hasBorders = false;

  // these have to be set for when we're updating an object (addObject isn't called, which normally sets these for everything)
  newObject.hoverCursor = 'none';
  newObject.moveCursor = 'none'

  // this has to happen here because on updates, a new object is created to compare to the old object and new objects have default opacity and selectability values that need to be set correctly
  setObjectOpacityAndSelectability(newObject, board);

  return newObject
}