
var board;
var base = fabric.Canvas.prototype;

$( document ).on('board:created', function(event, b) { board = b; });

const visibilityLayerParams = function() {
  return {
    left: 0,
    top: 0,
    width: board.mapWidth, 
    height: board.mapHeight,
    selectable: false,
    evented: false,
    fill: '#202020',
    objectCaching: false,
  }
}

const newDarknessLayer = function() {
  return new fabric.Rect({ 
    ...visibilityLayerParams(),
    darknessLayer: true,
    opacity: 1,
  });
}

const newGmDarknessLayer = function() {
  return new fabric.Rect({ 
    ...visibilityLayerParams(),
    darknessLayer: true,
    opacity: 0.5,
  });
}

const newDimnessLayer = function() {
  return new fabric.Rect({ 
    ...visibilityLayerParams(),
    dimnessLayer: true,
    opacity: 0.5,
  });
}

const resizeVisibilityLayer = function(layer) {
  if(layer != null && (layer.width != board.mapWidth || layer.height != board.mapHeight)) {
    layer.width = board.mapWidth;
    layer.height = board.mapHeight;
  } 
}

const resizeVisibilityLayers = function() {
  resizeVisibilityLayer(board.darknessLayer);
  resizeVisibilityLayer(board.dimnessLayer);
  resizeVisibilityLayer(board.gmDarknessLayer);
}

const addVisibilityLayerIfNeeded = function(layerName, newLayerFunction) {
  if(board[layerName] == null) { 
    board[layerName] = newLayerFunction();
    board.addObject(board[layerName]);
  }
}

const removeVisibilityLayerIfNeeded = function(layerName) {
  if (board[layerName] != null) {
    board.remove(board[layerName]);
    board[layerName] = null;
  }
}

base.addVisibilityLayers = function() {
  resizeVisibilityLayers();

  // if we're a gm and we're not selecting a token, add the special gm darkness layer
  if(board.currentUserIsGm && !board.tokenSelected()) {
    removeVisibilityLayerIfNeeded("darknessLayer");
    if(board.lighting == 'dark')
      addVisibilityLayerIfNeeded("gmDarknessLayer", newGmDarknessLayer);
    else {
      removeVisibilityLayerIfNeeded("gmDarknessLayer");
    }
  } else {
    addVisibilityLayerIfNeeded("darknessLayer", newDarknessLayer);
    removeVisibilityLayerIfNeeded("gmDarknessLayer");
  }
  if(board.lighting == 'bright') { 
    // remove dimness layer if we're in bright light
    removeVisibilityLayerIfNeeded("dimnessLayer");
  } else {
    // add dimness layer in any other case
    addVisibilityLayerIfNeeded("dimnessLayer", newDimnessLayer);
  }
}

base.removeVisibilityLayers = function() {
  removeVisibilityLayerIfNeeded("dimnessLayer");
  removeVisibilityLayerIfNeeded("darknessLayer");
  removeVisibilityLayerIfNeeded("gmDarknessLayer");
}

