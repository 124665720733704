import uniqueId from "../../lib/unique_id";
import * as requests from "./board_requests"

var board;
var base = fabric.Canvas.prototype;
var rulers = [];
var currentX, currentY, currentBackendX, currentBackendY, removeRulerTimeout, totalDistanceText; 
const textSize = 20;
const totalTextSize = 25;
const lineWidth = 3;
const endpointRadius = 3;
const textOffset = 45;

$( document ).on('board:created', function(event, b) { board = b });

export const updateRulerForZoom = function(object) {
  if (!object.ruler) { return }
  if(object.text != null) {
    if (object.rulerTotalText) {
      object.fontSize = Math.round(totalTextSize / board.getZoom());
    } else {
      object.fontSize = Math.round(textSize / board.getZoom());
    }
  }
  if(object.type == 'circle') {
    object.radius = Math.round(endpointRadius / board.getZoom());
  }
  if(object.type == 'line') {
    object.strokeWidth = Math.round(lineWidth / board.getZoom());
  }
} 

const currentRuler = function() {
  return rulers[rulers.length - 1];
}

const snapToGrid = function(coordinate) {
  return  Math.floor(coordinate / board.blockSize) * board.blockSize + (board.blockSize / 2);
}

const updateRuler = function(x, y)  {
  if(board.rulerSnapToGrid) {
    x = snapToGrid(x);
    y = snapToGrid(y);
  }

  currentRuler().line.set({ x2 : x, y2 : y });
  currentX = x;
  currentY = y;
  currentRuler().endpoint2.set({ left: x, top: y });
  if(board.rulerShowOthers) {
    updateRulerForOtherPlayers(x,y);
  }
}

const updateTotalDistanceText = function() {
  if(!totalDistanceText) { return }
  const distanceX = currentRuler().line.x2 - currentRuler().line.x1;
  const distanceY = currentRuler().line.y2 - currentRuler().line.y1;

  const angle = Math.atan2(distanceY, distanceX);
  const xIncrease = (textOffset / board.getZoom()) * Math.cos(angle);
  const yIncrease = (textOffset / board.getZoom()) * Math.sin(angle);

  
  const unit = board.rulerUnit == 'meters' ? 'm' : 'ft';
  const totalDistance = rulers.reduce((sum, ruler) => {
    const textValue = parseFloat(ruler.text.text.trim().split(' ')[0]);
    return sum + (isNaN(textValue) ? 0 : textValue);
  }, 0);
  totalDistanceText.set({ 'text': ` ${totalDistance} ${unit} `, 'left': currentRuler().line.x2 + xIncrease , 'top': currentRuler().line.y2 + yIncrease });
}

const updateRulerText = function() {
  var pixelDistance;
  var distanceX = currentRuler().line.x2 - currentRuler().line.x1;
  var distanceY = currentRuler().line.y2 - currentRuler().line.y1;
  if(board.rulerDiagonalsAsFiveFeet) {
    pixelDistance = Math.max(Math.abs(distanceX), Math.abs(distanceY));
  } else {
    pixelDistance = Math.hypot(distanceX, distanceY);
  }
  var feetDistance = Math.round(pixelDistance * board.feetPerPixel);
  var textAngle = Math.atan2(distanceY, distanceX) + (Math.PI / 4);
  var textX = currentRuler().line.x1 + (distanceX / 3) + (30 * Math.cos(textAngle));
  var textY = currentRuler().line.y1 + (distanceY / 3) + (30 * Math.sin(textAngle));

  if(board.rulerUnit == 'meters') {
    const metersDistance = (feetDistance * 0.3).toFixed(1);
    currentRuler().text.set({'text': ` ${metersDistance} m `, 'left': textX, 'top': textY });
  } else {
    currentRuler().text.set({'text': ` ${feetDistance.toString()} ft `, 'left': textX, 'top': textY });
  }
  updateTotalDistanceText();
}


export const updateAllRulerObjects = function(x, y) {
  updateRuler(x, y);
  updateRulerText();
  board.renderAll();
}

const handleMouseMoveForRuler = function(options) {
  if(!board.isRulerMode) { return }
  if(currentRuler() == null) { return }
  if(board.contextualMenuOpen) { return }

  updateAllRulerObjects(options.absolutePointer.x, options.absolutePointer.y);
}
const newRulerText = function(totalText) {
  var rulerText = new fabric.Text('', { 
    originX: 'center',
    originY: 'center',
    stroke: board.rulerColor,
    fill: board.rulerColor,
    fontFamily: 'arial',
    backgroundColor: 'white',
    ruler: true,
    uid: uniqueId(),
    doNotPersistInDatabase: true,
    rulerTotalText: totalText
  });
  updateRulerForZoom(rulerText);
  return rulerText;
}

const newRulerEndpoint = function(startX, startY) {
  var endpoint = new fabric.Circle({
    left: startX,
    top: startY,
    originX: 'center',
    originY: 'center',
    fill: board.rulerColor,
    stroke: board.rulerColor,
    ruler: true,
    uid: uniqueId(),
    doNotPersistInDatabase: true
  });
  updateRulerForZoom(endpoint);
  return endpoint;
}
const newRuler = function(startX, startY) {
  if(board.rulerSnapToGrid) {
    startX = snapToGrid(startX);
    startY = snapToGrid(startY);
  }
  if(currentlyShowingRuler()) {
    startX = currentRuler().line.x2;
    startY = currentRuler().line.y2;
  }
  var line = new fabric.Line([startX, startY, startX, startY], {
    fill: board.rulerColor,
    stroke: board.rulerColor,
    originX: 'center',
    originY: 'center',
    strokeWidth: Math.round(3 / board.getZoom()),
    ruler: true,
    selectable: false,
    uid: uniqueId(),
    doNotPersistInDatabase: true,
  });
  updateRulerForZoom(line);
  return line;
}

const insideMap = function(x,y) {
  return x >= 0 && x <= board.mapWidth && y >= 0 && y <= board.mapHeight
}

const updateRulerForOtherPlayers = function(x, y) {
  if(!board.rulerShowOthers) { return }
  setTimeout(function() {
    // only update backend if we're on the same location after a period of time 
    if(x == currentX && y == currentY && (currentBackendX != currentX || currentBackendY != currentY) ) {
      currentBackendX = currentX;
      currentBackendY = currentY;
      requests.updateRequest(currentRuler().line);
      requests.updateRequest(currentRuler().text);
      requests.updateRequest(currentRuler().endpoint1);
      requests.updateRequest(currentRuler().endpoint2);
      if(totalDistanceText) {
        requests.updateRequest(totalDistanceText);
      }
    }
  }, 200)
}
function currentlyShowingRuler() {
  return currentRuler() != null && currentRuler().line.opacity == 1;
}

export const startRuler = function(options) {
  if(!insideMap(options.absolutePointer.x, options.absolutePointer.y)) { return }
  var line = newRuler(options.absolutePointer.x, options.absolutePointer.y)
  
  var ruler = {
    line: line,
    text: newRulerText(),
    endpoint1: newRulerEndpoint(line.x1, line.y1),
    endpoint2: newRulerEndpoint(line.x2, line.y2),
  };
  if(board.rulerShowOthers) {
    requests.createRequest(ruler.line);
    requests.createRequest(ruler.text);
    requests.createRequest(ruler.endpoint1);
    requests.createRequest(ruler.endpoint2);
  }
  board.addObject(ruler.text);
  board.addObject(ruler.line);
  board.addObject(ruler.endpoint1);
  board.addObject(ruler.endpoint2);
  if(currentlyShowingRuler()) {
    if(!totalDistanceText) {
      totalDistanceText = newRulerText(true);
      board.addObject(totalDistanceText);
      if(board.rulerShowOthers) {
        requests.createRequest(totalDistanceText);
      }
    }
    clearTimeout(removeRulerTimeout);
    rulers.push(ruler);
    updateAllRulerObjects(options.absolutePointer.x, options.absolutePointer.y);
  } else {
    rulers = [ruler];
  }
  // remove any existing mouse move handlers (in case this gets called again without removing the first)
  board.off('mouse:move', handleMouseMoveForRuler);
  board.on('mouse:move', handleMouseMoveForRuler);
}

base.handleMouseDownForRuler = function(options) {
  if(!board.isRulerMode) { return }
  startRuler(options);
}

function fadeRemoveRulerObject(object) {
  board.fadeRemoveTimer(object);
  if(board.rulerShowOthers) {
    // setting as temporary will cause it to fade out on other players screens
    object.set({ temporary: true });
    requests.updateRequest(object);
  }
}
function fadeRemoveAllRulerObjects() {
  rulers.forEach(function(ruler) {
    fadeRemoveRulerObject(ruler.line);
    fadeRemoveRulerObject(ruler.text);
    fadeRemoveRulerObject(ruler.endpoint1);
    fadeRemoveRulerObject(ruler.endpoint2);
  });
  if(totalDistanceText) {
    fadeRemoveRulerObject(totalDistanceText);
    totalDistanceText = null;
  }
  rulers = [];
}

export const stopRuler = function() {
  removeRulerTimeout = setTimeout(function() {
    fadeRemoveAllRulerObjects();
  }, 1000);
}

// Always remove rulers when the browser is closed/refreshed
window.addEventListener('beforeunload', function() {
  fadeRemoveAllRulerObjects();
});


base.handleRulerOnMouseUp = function(options) {
  if(!board.isRulerMode) { return }
  stopRuler();
  board.off('mouse:move', handleMouseMoveForRuler);

  board.mouseUpPerformed = true;
}

