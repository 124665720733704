import { moveObjectsToLayers } from "../custom/board/board_layers";
import consumer from "./consumer"

var channel;

$( document ).on('board_id:set', function(event, board) {

  if (channel != null) {
    channel.unsubscribe();
  }
  if (board == null) { return }
  const boardId = board.id

  channel = consumer.subscriptions.create({ channel: "BoardChannel", board_id: boardId }, {
    received(data) {
      var board = window.__board;

      board.set(data);
      if(data.mapHeight != null || data.mapWidth != null) {
        board.refreshGrid();
        board.updateVisibility();
      }
      if (data.gridColor != null || data.displayGrid != null) {
        board.redrawGrid();
      }
      if (data.lighting != null) {
        board.updateVisibility();
      }
      if (data.restrictVisibility != null) {
        board.updateVisibility();
      }
      if (data.tokenStackingOrder != null || data.imageStackingOrder != null) {
        moveObjectsToLayers();
        board.renderAll();
      }

      board.updateDebugWalls();
    }
  });
});