
import isMobile from "../../lib/is_mobile";
import uniqueId from "../../lib/unique_id";
import { updateCursorElementToMousePosition, updateCursorIcon } from "../board_cursor";
import * as requests from "./board_requests"

var board;
var base = fabric.Canvas.prototype;

$( document ).on('board:created', function(event, b) { board = b });


export function getNextPlacingCharacterElement() {
  const characterIdElement = $('.placing-character:visible');
  if(characterIdElement.length == 0) return;
  
  return characterIdElement.first()
}

export function getNextPlacingImageElement() {
  const characterIdElement = $('.placing-image:visible');
  if(characterIdElement.length == 0) return;
  
  return characterIdElement.first()
}

export function getNextPlacingShapeElement() {
  const characterIdElement = $('.placing-shape:visible');
  if(characterIdElement.length == 0) return;
  
  return characterIdElement.first()
}

export function findPlacingCharacterId() {
  const characterIdElement = getNextPlacingCharacterElement();
  if(characterIdElement == null) return;
  
  const characterId = characterIdElement.data('characterId')
  if(characterId == null || characterId == "") return;

  return characterId;
}

export function findPlacingImageId() {
  const imageIdElement = getNextPlacingImageElement();
  if(imageIdElement == null) return;
  
  const imageId = imageIdElement.data('imageId')
  if(imageId == null || imageId == "") return;

  return imageId;
}

export function findPlacingShapeId() {
  const shapeIdElement = getNextPlacingShapeElement();
  if(shapeIdElement == null) return;
  
  const shapeId = shapeIdElement.data('shapeId')
  if(shapeId == null || shapeId == "") return;

  return shapeId;
}

function decrementOrRemovePlacingElementAlert(element, event) {
  if (element == null) { return }
  const placingObjectCountElement = element.find('.placing-object-count');
  
  if (placingObjectCountElement.length > 0) {
    const count = parseInt(placingObjectCountElement.text());
    
    if (count > 2) {
      placingObjectCountElement.text(count - 1);
    } else {
      placingObjectCountElement.closest('.placing-object-multiplier').remove();
    }
  } else {
    element.remove();
    updateCursorIcon();
    if(!isMobile()) {
      updateCursorElementToMousePosition(event);  // only needed when canceling placing something
    }
  }

}

base.handlePlacingCharacterImageOrShapeOnMouseUp = function(options) {
  const characterId = findPlacingCharacterId();
  const imageId = findPlacingImageId();
  const shapeId = findPlacingShapeId();

  let placingAlertElement;
  if(characterId != null) { 
    placingAlertElement = getNextPlacingCharacterElement();
    const loadingIconUuid = addLoadingIcon(options.absolutePointer.x, options.absolutePointer.y);
    requests.placeCharacter(characterId, options.absolutePointer.x, options.absolutePointer.y, loadingIconUuid);
  } else if (imageId != null) {
    placingAlertElement = getNextPlacingImageElement();
    const loadingIconUuid = addLoadingIcon(options.absolutePointer.x, options.absolutePointer.y);
    requests.placeImageFromBoardImage(imageId, options.absolutePointer.x, options.absolutePointer.y, loadingIconUuid);
  } else if (shapeId != null) {
    placingAlertElement = getNextPlacingShapeElement();
    const loadingIconUuid = addLoadingIcon(options.absolutePointer.x, options.absolutePointer.y);
    requests.placeShape(shapeId, options.absolutePointer.x, options.absolutePointer.y, loadingIconUuid);
  }

  if (placingAlertElement != null) {
    decrementOrRemovePlacingElementAlert(placingAlertElement.closest('.game-alert'), options.e);
    board.mouseUpPerformed = true;
  }
}


export const addLoadingIcon = function(x, y) {
  const path = "M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"
  const uuid = uniqueId();
  const top = Math.floor(y / board.blockSize) * board.blockSize + 1 + (board.blockSize / 2);
  const left = Math.floor(x / board.blockSize) * board.blockSize + 1 + (board.blockSize / 2);

  var newObject = new fabric.Path(path, { originX:'center', originY:'center', top: top, left: left, loadingIcon: true, fill: '#948bff', uid: uuid });
  newObject.scale(0.04);
  newObject.hasControls = false;
  newObject.hasBorders = false;
  newObject.hoverCursor = 'none';
  newObject.moveCursor = 'none'
  newObject.animate('angle', '+=2000', { duration: 30000, onChange: board.renderAll.bind(board), easing: fabric.util.ease.easeOutCubic });


  board.addObject(newObject);
  board.renderAll();
  return uuid;
}

$(document).on('click', '.cancel-placing', function (e) {
  decrementOrRemovePlacingElementAlert($(this).closest('.game-alert'), e);

});
