import isMobile from "../../lib/is_mobile";
import updatePageContent from "../update_page_content";
import * as pageStack from "./page_stack"
import addUnloadedSlidePageTemplates from "./add_unloaded_slide_page_templates";
import { isScrollToBottomUrl } from "../scroll_to_bottom";

const slideTime = 300;


export function refreshModalContent(urls) {
  for(const url of urls) {
    const divs = $("div").find(`[data-content-url^='${url}']`)
    if( divs.length ) {
      divs.each(function() {
        const div = $(this);
        const scrollPosition = $('.modal-body').scrollTop();
        $.get( div.data('content-url'), function( response ) {
          updatePageContent(div, response, 'full');
          $('.modal-body').scrollTop(scrollPosition);
        });
      })
    }
  }
}

export function replaceModalContent(url) {
  pageStack.replaceCurrent(url);
  $.get( url, function( data ) {
    updatePageContent($('#modal-content-primary'), data, 'inner')
  });
}


export function startNav(url, initialHistory) {
  pageStack.start(initialHistory);
  pageStack.add(url);
  $('.loading-indicator').removeClass('d-none');

  const width = $('#right-modal .modal-dialog').css('width');

  if ($('#right-modal').css('right') == `-${width}`) {
    $('.slideout').animate({'right':'0px'});
    $('#right-modal-main-spacer').animate({'width':width});
    const main = document.getElementById('main');
    if(!isMobile()) {
      window.__board.usefulPan(- (parseInt(width) / 2), 0, true);
    }
  }
  replaceModalContent(url);
}

export function closeNav() {
  const width = $('#right-modal .modal-dialog').css('width');

  if ($('#right-modal').css('right') == '0px') {
    $('#right-modal-main-spacer').animate({'width':'0px'});
    $('.slideout').animate({'right':`-${width}`}, function() {
      updatePageContent($('#modal-content-primary'), '', 'inner')
    });
    const main = document.getElementById('main');
    if(!isMobile()) {
      window.__board.usefulPan( parseInt(width) / 2, 0, true);

    }
  }
}

export function deleteNonActiveSlideContent() {
  // specify the slide navs by name to avoid removing nav within navs
  $(`.slide-nav.slide-panel-header > .slide-nav-page:not(.active)`).remove();
  $(`.slide-nav.slide-panel-subheader > .slide-nav-page:not(.active)`).remove();
  $(`.slide-nav.slide-panel-body > .slide-nav-page:not(.active)`).remove();
}

function animateHeight(slideFromPage, slideToPage) {
  var slideFromPageHeight = slideFromPage.outerHeight();
  var slideToPageHeight = slideToPage.outerHeight();
  if (slideFromPageHeight < slideToPageHeight) {
    slideToPage.height(slideFromPageHeight);
    slideToPage.animate({ height: slideToPageHeight }, slideTime, function() { slideToPage.css('height', 'auto');  });
  } else if (slideFromPageHeight > slideToPageHeight) {
    slideFromPage.animate({ height: slideToPageHeight }, slideTime, function() { 
      slideFromPage.css('height', 'auto');
      slideFromPage.css("display", ""); // For some reason, the display is sometimes getting set to none.  That is leading to an issue where when you try to go back from a page that didn't have a subheader, the subheader on the page youre trying to go back to is set to display none so it just doesnt ever show up. Im not sure where/how it's getting set to display none but making sure there is no inline display set here appears to solve the problem
    });

  }
}


function findClosestSlideNavToClick(clickTarget) {
  // if we dont have a clickTarget, then assume we're trying to transition the highest level slide-nav (clickTarget is really only used for navs within navs)
  if (clickTarget == null) {
    return $('.slide-nav.slide-panel-body');
  } else {
    return $(clickTarget.closest('.slide-nav'));
  }
}

export function slideTo({slideToPath, direction = 'right', removeStalePages = false, slideSubheader= false, slideMainNavOverride = false, clickTarget, callback}) {
  var slideToPathWithoutParams = slideToPath.split('?')[0];
  var slideToPages = findSlideToPagesFromPath();
  var closestSlideNavToClick = findClosestSlideNavToClick(clickTarget);
  var slidingNavWithinNav = findIfSlidingNavWithinNav();
  if (!slidingNavWithinNav) { closestSlideNavToClick = $('.slide-nav.slide-panel-body') }

  if(slideToPages.length == 0) {
    // if we can't find the page to slide to, assume we're at the top level nav (will need to change if we support dynamically loading pages for nav within navs)
    var slideFromPath = closestSlideNavToClick.find ('> [data-slide-page].active').data('slidePage');
    addUnloadedSlidePageTemplates(slideToPathWithoutParams, slideFromPath, direction, slideSubheader, slidingNavWithinNav);
    slideToPages = findSlideToPagesFromPath();
  }
  var slideNavs = slideToPages.closest('.slide-nav');
  var slideFromPages = slideNavs.find('> [data-slide-page].active');
  const width = slideNavs.outerWidth();
  var slideToPageUnloaded = slideToPages.filter(function() { 
    return $(this).data("slideLoaded") == false || $(this).data("slideAlwaysReload") == true;
  });


  function findSlideToPagesFromPath() {
    return $('[data-slide-page]').filter(function() {
      if($(this).hasClass('active')) { return false }  // Used so we dont slide on the header when using subheaders
      return slideToPathWithoutParams == $(this).data("slidePage")
    });
  }

  function loadContentIfNecessary () {
    if(slideToPageUnloaded.length == 0) { return }
    updatePageContent(slideToPageUnloaded, '', 'inner');  // clear the page while we wait for new content
    $.ajax({
      url: slideToPath,
      data: { inner_load: true },
      type: 'GET',
      success: function (data) {
        updatePageContent(slideToPageUnloaded, data.new_content, 'full');
        updateHeaderBackButtonAndResizeSubheader();
        if (callback != null) { callback() };
      }
    });
  }

  function updateHeaderPath(pathWithoutParams) {
    $('.slide-panel-header .active').attr('data-slide-page', pathWithoutParams);
  }

  function findIfSlidingNavWithinNav() {
    if (slideMainNavOverride == true) {
      return false
    }
    return !closestSlideNavToClick.hasClass('slide-panel-header') && !closestSlideNavToClick.hasClass('slide-panel-subheader') && !closestSlideNavToClick.hasClass('slide-panel-body');
  }

  function updateHeaderBackButtonAndResizeSubheader() {
    updateHeaderBackButton();
    resizeSubheader();
    resizeFooter();
  }

  function resizeSubheader() {
    const subheaderFilter = function() { return $(this).closest('.slide-nav').hasClass('slide-panel-subheader') };
    var slideFromSubheader = slideFromPages.filter(subheaderFilter);
    var slideToSubheader = slideToPages.filter(subheaderFilter);
    animateHeight(slideFromSubheader, slideToSubheader);
  }

  function resizeFooter() {
    const footerFilter = function() { return $(this).closest('.slide-nav').hasClass('slide-panel-footer') };
    var slideFromFooter = slideFromPages.filter(footerFilter);
    var slideToFooter = slideToPages.filter(footerFilter);
    animateHeight(slideFromFooter, slideToFooter);
  }

  function updateHeaderBackButton() {
    // if we're in a nav within a nav then just dont touch the back button
    if (slidingNavWithinNav) { return } 
    if ($(`.slide-panel-subheader [data-slide-page='${slideToPathWithoutParams}'] .slide-back`).length > 0) {
      $('.slide-nav.slide-panel-header [data-slide-page].active .header-back-button').collapse('hide')
    } else {
      $('.slide-nav.slide-panel-header [data-slide-page].active .header-back-button').collapse('show')
    }
  }

  const resizeNavWithinNav = function() {
    if(!slidingNavWithinNav) { return }
    // assume there is only one slide nav in these cases 
    var slideFromPage = $(slideFromPages[0]);
    var slideToPage = $(slideToPages[0]);
    var slideNav = $(slideNavs[0]);
    animateHeight(slideFromPage, slideToPage);
  }

  const startSlide = function() {
    if (direction == 'right' && !slidingNavWithinNav) {
      pageStack.add(slideToPath);
    }
    slideToPages.addClass('active');
    resizeNavWithinNav();

    if(slideSubheader) { updateHeaderPath(slideToPathWithoutParams) }
    if(!slidingNavWithinNav) {
      // most pages we scroll to top, but a few are scroll to bottom (which is done when finishing)
      if(!isScrollToBottomUrl(slideToPath)) {
        var modalBody = slideNavs.closest('.modal-body');
        modalBody.animate({ scrollTop: 0 }, slideTime);
      }
    }
    slideNavs.css('overflow', 'hidden'); // this needs to be set to hidden during slides and back to visible afterwards so that dropdowns display properly
    if (slideToPageUnloaded.length == 0) { updateHeaderBackButtonAndResizeSubheader() }
    if(slideFromPages.index() < slideToPages.index()) {
      slideRight();
    } else {
      slideLeft();
    }    
  }

  const finishSlide = function() {
    slideFromPages.removeClass('active');
    slideNavs.scrollLeft(0);
    slideNavs.css('overflow', 'visible');
    if (!slidingNavWithinNav) { slideNavs.closest('[data-content-url]').attr('data-content-url', slideToPath) }
    if (removeStalePages) { deleteNonActiveSlideContent() }
    if(isScrollToBottomUrl(slideToPath)) {
      var modalBody = document.getElementById("modal-body-id");
      modalBody.scrollTop = modalBody.scrollHeight;
    } 
  }

  const slideRight = function() {
    slideNavs.animate({ scrollLeft: width }, slideTime, finishSlide);
  }

  const slideLeft = function() {
    slideNavs.scrollLeft(width);
    slideNavs.animate({ scrollLeft: 0 }, slideTime, finishSlide);  
  }

  loadContentIfNecessary();
  startSlide();

}


export function slideBack({ removeStalePages = false, slideSubheader = false, clickTarget }) {
  if (removeStalePages) { deleteNonActiveSlideContent(); }
  pageStack.removeCurrent();
  const slideToPath = pageStack.getCurrent();
  if(slideToPath == null) {
    closeNav();
  } else {
    // remove stale pages here in case we want to delete the page we were on
    slideTo({ slideToPath: slideToPath, direction: 'left', removeStalePages: removeStalePages, clickTarget: clickTarget, slideSubheader: slideSubheader });
  }
}

export function conditionalUrlSlideBack(url) {
  if($(`[data-content-url^='${url}']:visible`).length >0 ) {
    slideBack({});
  }
}
