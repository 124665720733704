
const scrollToBottomUrls = ["/assistant_chats/", "show_ai_content", "/ai_contents/", "scroll_to_bottom"]

export function isScrollToBottomUrl(url) {
  return scrollToBottomUrls.some(pattern => {
    const regex = new RegExp(pattern);
    return regex.test(url);
  });
}

$( document ).on('page_content_updated', function(event, newContent) {
  const scrollToBottomPage =scrollToBottomUrls.some(pattern => {
    return $(`[data-content-url*='${pattern}']`)[0] != null
  });
  if(scrollToBottomPage) {
    var objDiv = document.getElementById("modal-body-id");
    if (objDiv != null) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  }
});

// // Useful function for finding what is scrolling (just copy it into browser)
// function findScroller(element) {
//   element.onscroll = function() { console.log(element), console.log(element.scrollHeight)}

//   Array.from(element.children).forEach(findScroller);
// }

// findScroller(document.body);


