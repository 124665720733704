
export default function updatePageContent(tag, newContent, type = 'inner') {
  if(type == 'inner') {
    tag.html(newContent);
  } else if (type == 'full') {
    tag.replaceWith(newContent);
  } else if (type == 'append') {
    tag.append(newContent);
  } else if (type == 'prepend') {
    tag.prepend(newContent);
  }
  var outsideContent = $(newContent).find('[data-content-for]')
  outsideContent.each(function() {
    var content = $(this);
    const contentForSelector = content.data('contentFor');
    $(contentForSelector).not(`.slide-nav-content-template ${contentForSelector}`).replaceWith(content.html());  //skip anything inside a .slide-nav-content-template
  })
  $('[data-content-for]').remove();

  $( document ).trigger('page_content_updated', newContent)
}