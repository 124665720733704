import mouseOrTouchPosition from "../../lib/mouse_or_touch_position"

const mouseMoveToDragBuffer = 5;

var board;
var base = fabric.Canvas.prototype;
var lastPosX, lastPosY;

base.allowDragScrolling = function() {
  if (board.isDrawingMode) return false
  if (board.isRulerMode) return false
  return (board.getActiveObject() == null)
}

base.usefulZoom = function(zoomTo, point = { x: (board.getWidth() / 2), y: (board.getHeight() / 2) }) {
  const maxMapSize = Math.max(board.mapWidth, board.mapHeight);
  if (zoomTo > 10) { return };
  if (zoomTo < ( 1 / maxMapSize) * 450  ) { return };

  board.zoomToPoint(point, zoomTo);
}

base.usefulPan = function(x = 0, y = 0, animate = false) {
  if (x == 0 && y == 0) { return }

  // allow animating pans
  if (animate) {
    var previousXValue = 0;
    var previousYValue = 0;      
    $({ percent:0 }).animate({ percent: 200 }, {
      step: function() {
        var currentXValue = (this.percent / 200) * x;
        var currentYValue = (this.percent / 200) * y;
        board.usefulPan( currentXValue - previousXValue, currentYValue - previousYValue );
        previousXValue = currentXValue;
        previousYValue = currentYValue;
      }
    })
  } else {
    var viewportTransform = this.viewportTransform;

    const currentLeftEdge = viewportTransform[4];
    const currentTopEdge = viewportTransform[5];
    const currentRightEdge = board.mapWidth * board.getZoom() + currentLeftEdge;
    const currentBottomEdge = board.mapWidth * board.getZoom() + currentTopEdge;
    
    const nextLeftEdge = currentLeftEdge + x;
    const nextTopEdge = currentTopEdge + y;
    const nextRightEdge = board.mapWidth * board.getZoom() + nextLeftEdge;
    const nextBottomEdge = board.mapHeight * board.getZoom() + nextTopEdge;

    if (nextRightEdge <  Math.min(200, currentRightEdge)) { return }
    if (nextBottomEdge <  Math.min(200, currentBottomEdge)) { return }
    if (nextLeftEdge > Math.max(board.getWidth() - 200, currentLeftEdge)) { return }
    if (nextTopEdge > Math.max(board.getHeight() - 200, currentTopEdge)) { return }

    viewportTransform[4] = nextLeftEdge;
    viewportTransform[5] = nextTopEdge;
    board.setViewportTransform(viewportTransform);  
  }
}

base.handleMouseDownForScrollDrag = function(options){
  if(!board.allowDragScrolling()) { return }
  if(options.e.touches != null && options.e.touches.length > 1) { return }
  board.startingDrag = true;
  board.maintainLastUndo = true;

  const position = mouseOrTouchPosition(options.e);
  lastPosX = position.x;
  lastPosY = position.y;
}

base.handleScrollDrag = function(options) {
  if (board.startingDrag == true) {
    const position = mouseOrTouchPosition(options.e);
    if(Math.abs(position.x - lastPosX) > mouseMoveToDragBuffer || Math.abs(position.y - lastPosY) > mouseMoveToDragBuffer) {
      board.startingDrag = false;
      board.isDragging = true;
    }
  }
  if (!board.isDragging) { return }

  const position = mouseOrTouchPosition(options.e);
  board.usefulPan(position.x - lastPosX, position.y - lastPosY)
  lastPosX = position.x;
  lastPosY = position.y;
}

$( document ).on('board:created', function(event, b) {  board = b });
