import * as requests from "./board_requests"
import uniqueId from "../../lib/unique_id"

var board;
var base = fabric.Canvas.prototype;

$( document ).on('board:created', function(event, b) { board = b });


base.handleClickingCombatants = function(options) {
  if(options.target == null) { return }
  if(options.target.token == null) { return }
  if($('#selecting-combatants').length == 0 ) { return }

  if(options.target.combatIndicatorObject != null) {
    requests.destroyInitiative(options.target);
  } else {
    requests.createInitiative(options.target);
  }
  board.mouseUpPerformed = true;
}