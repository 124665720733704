export function compareArrays(arr1, arr2) {
  // Convert single integers to single-element arrays
  if (typeof arr1 === 'number') arr1 = [arr1];
  if (typeof arr2 === 'number') arr2 = [arr2];

  const len = Math.min(arr1.length, arr2.length);
  
  for (let i = 0; i < len; i++) {
    if (arr1[i] < arr2[i]) return -1;
    if (arr1[i] > arr2[i]) return 1;
  }

  // If all elements are equal so far, compare lengths
  if (arr1.length < arr2.length) return -1;
  if (arr1.length > arr2.length) return 1;

  return 0; // Arrays are equal
}