
import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {

  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end,
      animation: 150,
    })
  }

  end(event) {
    var parent = $(event.item).parent();
    
    var idList = parent.children().map(function() {
        return $(this).data('id'); 
    }).get(); 

    let url = parent.data("sortListUrl");
    let key = parent.data("sortListKey");
    let kind = parent.data("sortListKind");
    let data = {key: key, kind: kind, list: idList }

    $.ajax({
      url: url,
      type: 'PUT',
      data: data
    })
  }
}
