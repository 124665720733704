
var board;
var base = fabric.Canvas.prototype;

$( document ).on('board:created', function(event, b) { board = b });

base.clickCanBeUndone = function(text) {
  board.undoClickId = board.currentClickId;
  board.undoText = text;
  board.maintainLastUndo = true;
}
