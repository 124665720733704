
function isWallLine(object) {
  return object.wall != null && object.type == 'line'
}

function isPlayerPin(object, board) {
  return object.pin == true && !board.currentUserIsGm;
}

function isLockedImage(object) {
  return object.type == 'image' && object.background != true && object.lockPosition == true;
}

function isLockedShape(object) {
  return object.shapeType != null && object.lockPosition == true;
}

function isUnlockedShape(object) {
  return object.shapeType != null && object.lockPosition != true;
}

function notDraggable(object, board) {
  return isPlayerPin(object, board) || isLockedImage(object) || isLockedShape(object)
}

function neverSelectable(object) {
  return object.door == true;
}

function neverSelectableOrEvented(object, board) {
  return isWallLine(object) || object.background == true || object.indicator == true || object.lock == true || object.unlockAttempt == true || (!board.currentUserIsGm && object.type == 'image') ||  (!board.currentUserIsGm && object.shapeType != null);
}

function isUnlockedImage(object) {
  return object.type == 'image' && object.background != true && !isLockedImage(object)
}

function hasBorderControls(object, board){
  return isUnlockedImage(object) || isUnlockedShape(object)
}

function setSelectableEventedAndLocked(object, board) {
  if(neverSelectableOrEvented(object, board)) { 
    object.selectable = false;
    object.evented = false;
  } else if (neverSelectable(object)) {
    object.selectable = false;
  } else {
    object.selectable = true;
    object.evented = true;
    if(hasBorderControls(object, board)) {
      object.hasControls = true;
      object.hasBorders = true;
    } else {
      object.hasControls = false;
      object.hasBorders = false;
    }
    if(notDraggable(object,board)) {
      // I really have no idea why these have to be done in a timeout.  But if they're not the values just do not update
      setTimeout(() => {
        object.lockMovementX = true;
        object.lockMovementY = true;
      }, 10)
    } else {
      setTimeout(() => {
        object.lockMovementX = false;
        object.lockMovementY = false;
      }, 10)
    }
    
  }
}

function getDesiredOpacity(object) {
  let desiredOpacity = object.setOpacity;
  if(desiredOpacity == null) { desiredOpacity = 100 }
  return desiredOpacity / 100;
}

function showObject(object, board) {
  object.opacity = getDesiredOpacity(object);
  setSelectableEventedAndLocked(object, board);
}

function hideObject(object) {
  object.opacity = 0;
  object.selectable = false;
  object.evented = false;
  // has controls and has border need to be set to false for images that disappear when assets toggle is changed
  object.hasControls = false;
  object.hasBorders = false;
}

function dimObject(object, board, dimRate = 0.5) {
  object.opacity = dimRate * getDesiredOpacity(object);
  setSelectableEventedAndLocked(object, board);
}

function handleLight(object, board) {
  if(board.currentUserIsGm) {
    if(board.isLightingMode || board.isErasingMode) {
      showObject(object, board)  
    } else if (board.gmLayerVisible) {
      dimObject(object, board)
    } else {
      hideObject(object)
    }
  } else {
    hideObject(object)
  }
}
function handleWall(object, board) {
  if(board.currentUserIsGm) {
    if(board.isWallingMode || board.isErasingMode) {
      showObject(object, board)  
    } else if (board.gmLayerVisible) {
      dimObject(object, board)
    } else {
      hideObject(object)
    }
  } else {
    hideObject(object)
  }
}

function handlePin(object, board) {
  if(object.showPlayers == true) { showObject(object, board) }
  else if (!board.currentUserIsGm || !board.gmLayerVisible) {
    hideObject(object)
  } else {
    dimObject(object, board)
  }
}

function handleToken(object,board) {

  if(board.currentUserIsGm && !board.tokensVisible) {
    hideObject(object);
  } else if (object.showPlayers == true) {
    if(board.currentUserIsGm && !board.tokensVisible) {
      hideObject(object);
    } else {
      showObject(object, board);
    }
  } else if (board.currentUserIsGm && board.gmLayerVisible) {
    dimObject(object, board)
  } else {
    hideObject(object);
  }
}

function handleIndicator(object,board) {
  if(object.associatedToken.showPlayers == true ) {
    showObject(object, board);
  } else if (board.currentUserIsGm && board.gmLayerVisible) {
    dimObject(object, board)
  } else {
    hideObject(object);
  }
}

function handleDoor(object, board) {
  if(object.associatedWall.doorState == 'open') {
    dimObject(object, board);
  } else {
    showObject(object, board);
  }
}

function handleLock(object, board) {
  if(board.currentUserIsGm && board.gmLayerVisible) {
    dimObject(object, board, 0.8);
  } else {
    hideObject(object, board);
  }
}

function handleUnlockAttempt(object, board) {
  showObject(object, board);
}

function handleImage(object, board) {
  if(object.background == true ) {
    showObject(object, board);
  } else if(board.currentUserIsGm && !board.assetsVisible) {
    hideObject(object);
  } else if (object.showPlayers == true) {
    if(board.currentUserIsGm && !board.assetsVisible) {
      hideObject(object);
    } else {
      showObject(object, board);
    }
  } else if (board.currentUserIsGm && board.gmLayerVisible) {
    dimObject(object, board)
  } else {
    hideObject(object);
  }
}

function handleRuler(object, board) {
  object.selectable = false;
  object.evented = false;
  object.opacity = 1;
}


export function setObjectOpacityAndSelectability (object, board) {
  if(object.lightSource != null) { handleLight(object, board) }
  if(object.wall != null) { handleWall(object,board) }
  if(object.pin == true) { handlePin(object, board) }
  if(object.token == true ) { handleToken(object,board) }
  if(object.indicator == true) { handleIndicator(object, board) }
  if(object.door == true) { handleDoor(object, board) }
  if(object.lock == true) { handleLock(object, board) }
  if(object.unlockAttempt == true) { handleUnlockAttempt(object, board) }
  if(object.type == 'image' || object.shapeType != null) { handleImage(object,board)}
  if(object.ruler == true) { handleRuler(object, board) }
}

export function setAllOpacityAndSelectability (board) {
  board.getObjects().forEach(object => {
    setObjectOpacityAndSelectability(object, board)
  });
  board.renderAll();
}
