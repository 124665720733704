import { finishDragging } from "./dropzone";
import { handleAjaxResponse } from "./handle_ajax_response"

var campaignId;
$( document ).on('campaign_id:set', function(event, cId) { campaignId = cId });


// general dragging
$(document).on('dragstart', '[draggable="true"]', function (event) {
  event.originalEvent.dataTransfer.setData("id", $(this).data('id'));
  const dragType = $(this).data('dragType');
  if (dragType != null){
    event.originalEvent.dataTransfer.setData("dragType", dragType);
    event.originalEvent.dataTransfer.setData(`${dragType}dragtype`, true);
  }
});

$(document).on('dragenter', '.droppable', function (event) {
  $(this).focus();
});

$(document).on('dragleave', '.droppable', function (event) {
  $(this).blur();
});

$(document).on('drop', function (event) {
  finishDragging();
});


// dragging on roll ability scores page
$(document).on('dragend', '.base-attribute-roll-wrapper', function (event) {
  if (event.originalEvent.dataTransfer.dropEffect != 'copy') return;
  $(event.target).remove();
});

$(document).on('drop', '.ability-score-drop', function (event) {
  
  const id = event.originalEvent.dataTransfer.getData('text');
  const ability_score_name = event.target.dataset['abilityScoreName'];
  $.ajax({
    type: "PUT",
    url: `/campaigns/${campaignId}/ability_score_rolls/${id}/mark_used?ability_score_name=${ability_score_name}`,
    success: function (data) {
      handleAjaxResponse(data, $(this));
    },
  });
  event.preventDefault();
});
