import { handleAjaxResponse } from "../handle_ajax_response"
import { startNav } from "../navigation/slide_nav"
import { getTarget } from "./board_contextual_menu";
var campaignId;

$( document ).on('campaign_id:set', function(event, cId) { campaignId = cId });

export function objectToJSON(object) {
  var json = object.toJSON(window.__board.customAttributes);
  // below is a wild hack to get lines to keep their coordinates (https://github.com/fabricjs/fabric.js/issues/5291)
  if(object.x1 != null) { json['x1'] = object.x1 }
  if(object.x2 != null) { json['x2'] = object.x2 }
  if(object.y1 != null) { json['y1'] = object.y1 }
  if(object.y2 != null) { json['y2'] = object.y2 }

  // for pins and shapes, convert to a simple format for backend
  if(json.pin != null) { json = { uid: json.uid, top: json.top, left: json.left, type: "pin", showPlayers: json.showPlayers, pinColor: json.pinColor, pinSize: json.pinSize } }
  
  if(json.ruler) {
    // these properties are set based on the current zoom
    delete json.fontSize;
    delete json.strokeWidth;
  }

  delete json.scaleX; // remove scaleX and scaleY from anything going to the backend.  We use scaleHeight and scaleWidth on the backend and translate to scaleY and scaleX
  delete json.scaleY 
  delete json.shadow; // we dont want to save shadow to backend because we highlight selected tokens on the frontend only

  if(json.shapeType == null && !json.ruler) {
    delete json.fill;   // saving this was causing issues for tokens so Im removing it for all objects just to keep more data separated.  But it is needed for shapes because the fill is editable directly on those.  We also need it for rulers because the fill is dictated by the person making the ruler
  }

  // don't send the opacity, selectable, evented properties because those change depending on gm or player
  delete json.opacity
  delete json.selectable 
  delete json.evented
  delete json.lockMovementX
  delete json.lockMovementY

  return json;

}

export function createRequest (object) {
  var jsonString = JSON.stringify(objectToJSON(object));
  $.ajax({
    type: "POST",
    url: `/campaigns/${campaignId}/board_objects`,
    data: { object: jsonString, click_id: window.__board.currentClickId},
    success: function (data) {
      handleAjaxResponse(data, $(this));
    }
  });
};

export function destroyRequest (objectIds) {
  $.ajax({
    url: `/campaigns/${campaignId}/board_objects/` + objectIds,
    type: 'DELETE',
    data: { click_id: window.__board.currentClickId }, 
    success: function (data) {
      handleAjaxResponse(data, $(this));
    }
  });
};

export function destroyUnpersistedRequest (objectId) {
  $.ajax({
    url: `/campaigns/${campaignId}/board_objects/${objectId}/destroy_unpersisted`,
    type: 'DELETE',
    success: function (data) {
      handleAjaxResponse(data, $(this));
    }
  });
};

export function updateRequest (object, callback = null) {
  var jsonObject = objectToJSON(object);

  $.ajax({
    url: `/campaigns/${campaignId}/board_objects/0/update_json`,
    type: 'PUT',
    data: {object: JSON.stringify(jsonObject), client_change_initiated_by_session_id: window.__board.sessionId, click_id: window.__board.currentClickId },
    success: function(data) {
      if (typeof(callback) == 'function') {
        callback();
      }
    }
  });
}

export function addCharacterTarget (attackerId, actionId, targetId, addingOnMobile) {
  let url = `/campaigns/${campaignId}/characters/${attackerId}/actions/${actionId}/add_target?target_id=${targetId}`
  if(addingOnMobile !=null) {
    url = url + `&adding_on_mobile=true`
  }
  $.ajax({
    type: "PUT",
    url: url,
    success: function (data) {
      handleAjaxResponse(data, $(this));
    },
  });
}

export function placeCharacter (uid, left, top, loadingObjectUid) {
  $.ajax({
    type: "PUT",
    url: `/campaigns/${campaignId}/board_objects/0/end_place_character?uid=${uid}&left=${left}&top=${top}&loading_object_uid=${loadingObjectUid}`,
    success: function (data) {
      handleAjaxResponse(data, $(this));
    },
  });
}

export function placeShape (id, left, top, loadingObjectUid) {
  $.ajax({
    type: "PUT",
    url: `/campaigns/${campaignId}/board_objects/0/end_place_shape?shape_id=${id}&left=${left}&top=${top}&loading_object_uid=${loadingObjectUid}`,
    success: function (data) {
      handleAjaxResponse(data, $(this));
    },
  });
}

export function placeImageFromBoardImage (id, left, top, loadingObjectUid) {
  $.ajax({
    type: "PUT",
    url: `/campaigns/${campaignId}/board_objects/0/end_place_image?board_image_id=${id}&left=${left}&top=${top}&loading_object_uid=${loadingObjectUid}`,
    success: function (data) {
      handleAjaxResponse(data, $(this));
    },
  });
}

export function placeImagesFromFiles (files, left, top, loadingObjectUid) {
  let formData = new FormData();
  $.each(files, function(i, file) {
    formData.append('files[]', file);
  });
  $.ajax({
    type: "POST",
    data: formData,
    contentType: false, 
    processData: false,
    url: `/campaigns/${campaignId}/board_objects/upload_and_place_images?left=${left}&top=${top}&loading_object_uid=${loadingObjectUid}`,
    success: function (data) {
      handleAjaxResponse(data, $(this));
    },
  });
}


export function selectCharacter (characterId) {
  $.ajax({
    url: `/campaigns/${campaignId}/characters/${characterId}/select_character`,
    type: 'PUT',
    success: function (data) {
      handleAjaxResponse(data, $(this));
    },
  });
}

export function deselectCharacter () {
  $.ajax({
    url: `/campaigns/${campaignId}/characters/0/select_character`,
    type: 'PUT',
    data: { push_select_change_to_board: true },
    success: function (data) {
      handleAjaxResponse(data, $(this));
    },
  });
}

export function loadObjectShow (options) {
  const object = getTarget(options);
  if (object == null) { return }

  var url;
  if (object.token != null) {
    url = `/campaigns/${campaignId}/characters/${object.uid}`;
  } else if (object.pin == true || (window.__board.currentUserIsGm && (object.lightSource != null || (object.wall == true && object.type == 'line') || (object.type == 'image' && object.background != true ) || object.shapeType != null))) {
    url = `/campaigns/${campaignId}/board_objects/${object.uid}`;
  } 
  else {
    return
  }
  startNav(url);
}

export function loadTokenStackingOrder() {
  const url = `/campaigns/${campaignId}/boards/${window.__board.boardId}/token_stacking_order`;
  startNav(url);
}

export function loadImageStackingOrder() {
  const url = `/campaigns/${campaignId}/boards/${window.__board.boardId}/image_stacking_order`;
  startNav(url);
}

export function loadBoardEdit (boardId) {
  const url = `/campaigns/${campaignId}/boards/${boardId}/edit`;
  startNav(url);
}

export function loadNewBoardObject() {
  const url = `/campaigns/${campaignId}/board_objects/new_character_template`;
  startNav(url);
}

export function createInitiative(object) {
  $.ajax({
    url: `/campaigns/${campaignId}/characters/${object.uid}/initiatives`,
    type: 'POST',
    success: function (data) {
      handleAjaxResponse(data, $(this));
    },
  });
}

export function destroyInitiative(object) {
  $.ajax({
    url: `/campaigns/${campaignId}/characters/${object.uid}/initiatives/0`,
    type: 'DELETE',
    success: function (data) {
      handleAjaxResponse(data, $(this));
    },
  });  
}

export function undo(clickId) {
  $.ajax({
    url: `/campaigns/${campaignId}/boards/${window.__board.boardId}/undo`,
    type: 'POST',
    data: { click_id: clickId },
    success: function (data) {
      handleAjaxResponse(data, $(this));
    },
  });  
}

export function doorStateChanged(opened) {
  $.ajax({
    url: `/campaigns/${campaignId}/door_state_changed`,
    type: 'PUT',
    data: { opened: opened },
    success: function (data) {
      handleAjaxResponse(data, $(this));
    },
  });  
}


export function unlockAttempted(wall_uid) {
  $.ajax({
    url: `/campaigns/${campaignId}/unlock_attempted`,
    type: 'PUT',
    data: { wall_uid: wall_uid },
    success: function (data) {
      handleAjaxResponse(data, $(this));
    },
  });  
}
