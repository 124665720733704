$( document ).on('campaign_id:set', function() {

  const main = document.getElementById('main');
  var lastZoom = window.__board.getZoom();

  const pinchStartHandler = function(e) {
    if (e.touches.length == 1) { return }
    lastZoom = window.__board.getZoom();
    board.isDragging = false;
    board.isPinching = true;
  };

  const pinchMoveHandler = function(e) {
    if (e.touches.length == 1) { return }

    var board = window.__board;

    var zoomTo = lastZoom * e.scale;
    board.usefulZoom(zoomTo);
    board.isDragging = false;
    board.isPinching = true;
    e.preventDefault();
    e.stopPropagation();
  };

  // Attach the handler
  main.addEventListener('touchmove', pinchMoveHandler);  
  main.addEventListener('touchstart', pinchStartHandler);  
})