import { handleAjaxResponse } from "../custom/handle_ajax_response"

// hit any associated endpoints
$(document).on('click', '[data-url]', function(event) {
  const data = $(this).data();
  if (data['url'] == null) return

  if($(this).hasClass('show-loading-indicator')) {
    $('.loading-indicator').removeClass('d-none');
  }
  $.ajax({
    url: data['url'],
    type: data['urlMethod'],
    success: function (data) {
      handleAjaxResponse(data, $(this));
    }
  });
});

$(document).on('click', '[data-url-redirect]', function(event) {
  const data = $(this).data();
  if (data['urlRedirect'] == null) return

  window.location.href=data['urlRedirect']
});
