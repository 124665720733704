import isMobile from "../lib/is_mobile"

$( document ).on('page_content_updated', function(event, newContent) {
  var input = $('.autofocus').first();
  if(input == null) { return }

  if (!isMobile()) {
    setTimeout(function(){ 
      input.focus();

      var tmpStr = input.val();
      input.val('');
      input.val(tmpStr);
      input.trigger('focus');
    }, 200);
  }
});
