import consumer from "./consumer"
import { handleNewContentData } from "../custom/handle_ajax_response"

$( document ).on('campaign_id:set', function(event, campaignId, userId) {
  consumer.subscriptions.create({ 
    channel: "UserContentUpdateChannel",
    user_id: userId,
    campaign_id: campaignId
    }, {
    received(data) {
      handleNewContentData(data);
    }
  });
});
