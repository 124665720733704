import * as requests from "./board_requests"
import uniqueId from "../../lib/unique_id"

var board;
var base = fabric.Canvas.prototype;

$( document ).on('board:created', function(event, b) { board = b });

base.shouldDisplayLights = function() {
  return board.isLightingMode || board.isErasingMode
}

base.addLightSourceListeners = function(lightSource) {
  lightSource.on('moved', board.updateVisibility);
}

const newLightSource = function(left, top) {
  const lightSource = new fabric.Circle({
    left: left,
    top: top,
    strokeWidth: 0,
    radius: 10,
    fill: 'blue',
    originX: 'center',
    originY: 'center',
    lockScalingX: true,
    lockScalingY: true,
    lockRotation: true,  
    hasBorders: false,
    hasControls: false,
    lightSource: true,
    brightLightRadius: board.brightLightRadiusDefault,
    dimLightRadius: board.dimLightRadiusDefault,
    uid: uniqueId()
  });
  board.addLightSourceListeners(lightSource);
  return lightSource;
}

export const addLight = function(options) {
  const lightSource = newLightSource(Math.round(options.absolutePointer.x), Math.round(options.absolutePointer.y))
  board.addObject(lightSource);
  requests.createRequest(lightSource);

  board.updateVisibility();
}

base.handleAddLightOnMouseUp = function(options) {
  if(!board.isLightingMode) { return }
  if(options.target != null && (options.target.token != null || options.target.lightSource != null) ) { return }

  addLight(options);
  board.mouseUpPerformed = true;
}