
// if you're inside a panel-list-item stop clicks otherwise clicking on dropdown options within the list item will trigger clicking the item itself
$(document).on('click', '.panel-list-item .dropdown-item', function (e) {
  e.stopPropagation();
  e.preventDefault();
});

$(document).on('click', '.panel-list .dropdown', function (e) {
  e.stopPropagation();
  e.preventDefault();
});

$(document).on('click', '.prevent-default', function (e) {
  e.stopPropagation();
  e.preventDefault();
});


$(document).on('shown.bs.dropdown', function (e) {
  $(e.target).find('input.form-control').select();
})
