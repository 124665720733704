
export default function createChart (ctx, max) {
  var chart = new Chart(ctx, {
    type: 'bar',
    data: {
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1
        }]
    },
    options: {
      animation: {
        duration: 0
      },
      tooltips: false,
      legend: {
        display: false
      },
      scales: {
          yAxes: [{
            display: false,
            ticks: {
              beginAtZero: true,
              max: max
            }
          }],
          xAxes: [{
            display: false 
        }]
      }
    }
  });
  return chart;
};