import { conditionalUrlSlideBack } from "../navigation/slide_nav";
import { removeCombatIndicatorObjects } from "./board_indicators";
import * as requests from "./board_requests"
import { eraseOrphanedLines, eraseWallLine } from "./board_walls_eraser";

var board;
var base = fabric.Canvas.prototype;

$( document ).on('board:created', function(event, b) { board = b });

export const eraseSelectedObject = function(options) {
  if(options.target == null) { return false }
  if(options.target.selectable == false) { return false }

  eraseOrphanedLines(options.target);
  requests.destroyRequest([options.target.uid]);
  conditionalUrlSlideBack(board.urlForObject(options.target));
  board.removeObject(options.target);
  board.clickCanBeUndone('remove');
  return true;
}

base.handleEraseOnMouseUp = function(options) {
  if(!board.isErasingMode) { return }

  board.mouseUpPerformed = eraseSelectedObject(options) || eraseWallLine(options);
}