import * as requests from "./board_requests"
import { startRuler, updateAllRulerObjects } from "./board_ruler";

var board;
var base = fabric.Canvas.prototype;
var currentLeft;
var currentTop;
var goalLeft;
var goalTop;
var moveDistance;
var moveDistanceInBlocks;
var token;
var currentRow;
var currentCol;
var sizeInBlocks;
var startRulerOnMove;

$( document ).on('board:created', function(event, b) { board = b; });

function range(startAt, size) {
  return [...Array(size).keys()].map(i => i + startAt);
}

const snapToGrid = function(position) {
  return Math.round(position / moveDistance) * moveDistance + 1
}

const canMoveTo = function(wallHash, col, row) {
  var index = `${col},${row}`
  return wallHash[index] != true
}

const canMoveToAll = function(wallHash, cols, rows) {
  if (board.currentUserIsGm && !board.isWallingMode ) { return true }
  var canMove = true
  cols.forEach(function(col) {
    rows.forEach(function(row) {
      if (!canMoveTo(wallHash, col, row)) {
        canMove = false;
      }
    })
  })
  return canMove;
}

// for all these methods we need to check for both veritcal and horizontal walls, 
// and for a range of rows/cols depending on how big the token is, and we need a second
// range for how many blocks to check (most tokens are 2 but tiny tokens are 1)
const canMoveLeft = function(numBlocks) {
  return canMoveToAll(board.verticalWalls, range(currentCol + 1 - numBlocks, numBlocks), range(currentRow, sizeInBlocks)) && 
         canMoveToAll(board.horizontalWalls, range(currentCol - numBlocks, numBlocks), range(currentRow + 1, sizeInBlocks - 1))
}

const canMoveRight = function(numBlocks) {
  var currentRightCol = currentCol + sizeInBlocks - 1
  return canMoveToAll(board.verticalWalls, range(currentRightCol + 1, numBlocks), range(currentRow, sizeInBlocks)) &&
         canMoveToAll(board.horizontalWalls, range(currentRightCol + 1, numBlocks), range(currentRow + 1, sizeInBlocks - 1))
}

const canMoveUp = function(numBlocks) {
  return canMoveToAll(board.horizontalWalls, range(currentCol, sizeInBlocks), range(currentRow + 1 - numBlocks, numBlocks)) &&
         canMoveToAll(board.verticalWalls, range(currentCol + 1, sizeInBlocks - 1), range(currentRow - numBlocks, numBlocks))
}

const canMoveDown = function(numBlocks) {
  var currentBottomRow = currentRow + sizeInBlocks - 1
  return canMoveToAll(board.horizontalWalls, range(currentCol, sizeInBlocks), range(currentBottomRow + 1, numBlocks)) &&
         canMoveToAll(board.verticalWalls, range(currentCol + 1, sizeInBlocks - 1), range(currentBottomRow + 1, numBlocks))
}

export const moveRight = function() {
  if (!canMoveRight(moveDistanceInBlocks)) { return }
  currentLeft = currentLeft + moveDistance;
}

export const moveLeft = function() {
  if (!canMoveLeft(moveDistanceInBlocks)) { return }
  currentLeft = currentLeft - moveDistance;
}

export const moveDown = function() {
  if (!canMoveDown(moveDistanceInBlocks)) { return }
  currentTop = currentTop + moveDistance;
}

export const moveUp = function() {
  if (!canMoveUp(moveDistanceInBlocks)) { return }
  currentTop = currentTop - moveDistance;
}

const updateVisibilityWhenAtRest = function(lastCol, lastRow) {
  const wallPointsCount = board.wallPointsCount ?? 0;
  var delay = 50;
  if (wallPointsCount > 100) {
    delay = 100;
  } else if (wallPointsCount > 300) {
    delay = 200;
  }
  setTimeout(function() {
    // only update visibility if we're still on the same col, row after a buffer time 
    if(lastCol == currentCol && lastRow == currentRow) {
      board.updateVisibility();
    }
  }, delay)
}

const updateBackendLocationWhenAtRest = function(lastCol, lastRow) {
  setTimeout(function() {
    // only update backend if we're still on the same col, row after a buffer time 
    if(lastCol == currentCol && lastRow == currentRow) {
      requests.updateRequest(token);
    }
  }, 200)
}

export const updateIndicatorObjectsPositions = function(token) {
  if(token.indicatorObjects != null) { 
    token.indicatorObjects.forEach(function(indicatorObject) {
      indicatorObject.set({ top: token.top + indicatorObject.offsetTop, left: token.left + indicatorObject.offsetLeft })
    })
  }
  if (token.combatIndicatorObject != null) {
    token.combatIndicatorObject.set({ top: token.top + token.combatIndicatorObject.offsetTop, left: token.left + token.combatIndicatorObject.offsetLeft })
    token.combatIndicatorObjectBackground.set({ top: token.top + token.combatIndicatorObject.offsetTop, left: token.left + token.combatIndicatorObject.offsetLeft })
  }
}

export const updateTokenLocation = function() {
  token.set({ top: snapToGrid(currentTop), left: snapToGrid(currentLeft) } )
  currentCol = Math.round(currentLeft / board.wallBlockSize);
  currentRow = Math.round(currentTop / board.wallBlockSize);
  var lastCol = currentCol;
  var lastRow = currentRow;


  updateIndicatorObjectsPositions(token);
  updateVisibilityWhenAtRest(lastCol, lastRow);
  updateBackendLocationWhenAtRest(lastCol, lastRow);
}

const moveTowardGoal = function() {  
  var lastLeft = currentLeft;
  var lastTop = currentTop;

  // only move in one direction at a time
  if(Math.abs(goalLeft - currentLeft) > Math.abs(goalTop - currentTop)) {
    if (goalLeft > currentLeft) {
      moveRight();
    } else if (goalLeft < currentLeft) {
      moveLeft();
    }
  } else {
    if (goalTop > currentTop) {
      moveDown();
    } else if (goalTop < currentTop) {
      moveUp();
    }
  }
  if (lastLeft == currentLeft && lastTop == currentTop) { return }  // if we haven't moved for any reason, stop trying
  updateTokenLocation();

  if (startRulerOnMove != null) {
    startRuler(startRulerOnMove);
    startRulerOnMove = null;
  }
  if (board.rulerWhileMoving) {
    updateAllRulerObjects(currentLeft + token.scaleWidth / 2, currentTop + token.scaleHeight / 2);
  }
  moveTowardGoal();
}

const allowTokenDragging = function(uid) {
  if (board.isDrawingMode) return false;
  if (board.isRulerMode) return false;
  return board.isSelectableCharacterId(uid);
}


base.handleTokenDrag = function(options) {
  if(options.target.token == null) { return }
  goalLeft = snapToGrid(options.target.left);
  goalTop = snapToGrid(options.target.top);

  // don't count this as a drag unless we're actually moving (that allows other clicks to 'count' even if they would 'drag' just a little bit)
  if(allowTokenDragging(options.target.uid) && (goalLeft != snapToGrid(currentLeft) || goalTop != snapToGrid(currentTop))) { 
    board.isDraggingObject = true;
    board.selectCharacterOnMouseInteraction(options);

    token.set({ top: snapToGrid(currentTop), left: snapToGrid(currentLeft) } )
    moveTowardGoal();
  } else {
    token.set({ top: snapToGrid(currentTop), left: snapToGrid(currentLeft) })
  }
}

base.storeStartValuesForTokenDrag = function(startingToken) {
  token = startingToken;
  moveDistance = (token.tokenSize == 'tiny') ? (board.blockSize / 2) : board.blockSize;
  moveDistanceInBlocks = Math.round(moveDistance / board.wallBlockSize);
  sizeInBlocks = Math.round(token.scaleWidth / board.wallBlockSize);
  currentLeft = token.left;
  currentTop = token.top;
}

base.handleMouseDownForTokenDrag = function(options) {
  if(options.target == null || !options.target.token ) { return }
  board.storeStartValuesForTokenDrag(options.target)
  if (board.rulerWhileMoving && allowTokenDragging(options.target.uid)) {
    startRulerOnMove = options;
  }
}
