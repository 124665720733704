import { compareArrays } from "../../lib/compare_arrays";

var board;

function alwaysOnTopToken(token, board, tokenSelected) {
  if(token.uid == board.selectedCharacterId ) { return true } // if it's your currently selected token, it should always be on top
  if(board.currentUserIsGm) {
    return !tokenSelected; // if you have a token selected then other tokens should not appear on top (you want to mimic the player view)
  }
  return board.selectableCharacterIds.includes(token.uid);
}

function playingAsPlayer(board, tokenSelected) {
  return !board.currentUserIsGm || tokenSelected;
}

function stackingNumber(list, object) {
  if(list.includes(object.uid)) { return - list.indexOf(object.uid) }
  return parseInt(object.createdAtInteger);
}

function tokenStackingNumber(object) {
  return stackingNumber(board.tokenStackingOrder, object)
}

function imageStackingNumber(object) {
  return stackingNumber(board.imageStackingOrder, object)
}


function objectLayer(object) {
  const tokenSelected = board.tokenSelected();
  if(object.background) { return 1 }
  if(object.uid == 'grid') { return 2 }
  if(object.type == 'image' || object.shapeType != null) { return [3, imageStackingNumber(object)] }
  if(object.darknessLayer != null && !playingAsPlayer(board, tokenSelected)) { return 4 }
  if(object.dimnessLayer != null) { return 5 }
  if(object.wall != null && object.type =='line') { return 6 }
  if(object.wall != null && object.type =='circle') { return 7 }
  if(object.lightSource != null) { return 8 }
  if(object.door == true) { return 9 }
  if(object.lock == true) { return 10 }
  if(object.token && !alwaysOnTopToken(object, board, tokenSelected) ) { return [11, tokenStackingNumber(object)] }
  if(object.indicator && !alwaysOnTopToken(object.associatedToken, board, tokenSelected) ) { return [11, tokenStackingNumber(object.associatedToken) + 0.5] }
  if(object.darknessLayer != null && playingAsPlayer(board, tokenSelected)) { return 12 }
  if(object.token && alwaysOnTopToken(object, board, tokenSelected) ) { return [13, tokenStackingNumber(object)] }
  if(object.indicator && alwaysOnTopToken(object.associatedToken, board, tokenSelected) ) { return [13, tokenStackingNumber(object.associatedToken) + 0.5] }
  if(object.ruler != null && (object.type =='line' || object.type == 'circle')) { return 14 }
  if(object.ruler != null ) { return 15 } // the text part of the ruler
  if(object.unlockAttempt == true) { return 16 }
  if(object.type == 'path') { return 17 }
  if(object.debug) { return 18 }
  return 0
}

export function moveObjectsToLayers() {
  board._objects.sort(function(x,y) { 
    return compareArrays(objectLayer(x),objectLayer(y)) 
  });
}

$( document ).on('board:created', function(event, b) { 
  board = b; 
  board.on('object:added', moveObjectsToLayers);
  board.on('path:created', moveObjectsToLayers);
});
