import createChart from "../lib/roll_chart"

export function openDetails(rollEvent) {
  if (rollEvent.hasClass('expanded')) return

  var details = rollEvent.find('.details')
  const id = details.data('rollId');

  const campaignId = window.__board.campaignId;
  $.get( `/campaigns/${campaignId}/roll_details/${id}`, function( data ) {

    var canvas = details.find('.roll-chart');
    const max = Math.max(...data.values) // use the max value to ensure graph takes up all possible vertical space
    var chart = createChart(canvas, max);
    canvas.data('chart', chart);
  
    var backgroundColors = Array(data.labels.length).fill('#ebf6fe');
    var borderColors = Array(data.labels.length).fill('#ebf6fe');

    for (var i = 0; i <= data.highlight_column; i++) {
      backgroundColors[i] = '#24c9c7';
      borderColors[i] = '#24c9c7';
    }

    chart.data.labels = data.labels;
    chart.data.datasets[0]['data'] = data.values;
    chart.data.datasets[0]['backgroundColor'] = backgroundColors;
    chart.data.datasets[0]['borderColor'] = borderColors;
    chart.update();

    details.find('.roll-min').text(data.min);
    details.find('.roll-max').text(data.max);
  });
  details.collapse('show')
  rollEvent.addClass('expanded')
}

export function openAllRollDetails() {
  const rollEvents = $('.roll-content:not(.expanded)').slice(-10);
  rollEvents.each( function() {
    openDetails($(this));
  });
}

export function showChatUserNames() {
  var userNames = $('.event-user-name');
  userNames.each(function(i) {
    if(i == 0) {
      $(userNames.get(i)).collapse('show');
    }
    if (userNames.get(i+1) != null && userNames.get(i+1).dataset['userId'] != this.dataset['userId']) {
      $(userNames.get(i+1)).collapse('show');
    }
  })
}

$( document ).on('board:created', function() {
  openAllRollDetails();
  showChatUserNames();
});