var pageStack = [];

export function add(path) {
  pageStack.push(path);
}

export function start(initialHistory) {
  if(initialHistory == null) { 
    pageStack = [];
  } else {
    pageStack = initialHistory
  }
}

export function removeCurrent() {
  pageStack.pop();
}

export function removeUrls(urls) {
  pageStack = pageStack.filter(url => !urls.includes(url));
}

export function removeUntil(url) {
  const currentUrl = pageStack.pop();
  while (pageStack != [] && getCurrent() != url) {
    pageStack.pop();
  }
  pageStack.push(currentUrl);
}
export function remove(count) {
  const currentUrl = pageStack.pop();
  [...Array(count)].map(() => pageStack.pop())
  pageStack.push(currentUrl);
}
export function replaceCurrent(url) {
  pageStack.pop();
  pageStack.push(url);
}

export function getCurrent() {
  return pageStack[pageStack.length - 1]
}
