import * as rollEvent from "../custom/roll_event"
import updatePageContent from "./update_page_content";
import { slideTo, slideBack, replaceModalContent, startNav, deleteNonActiveSlideContent, refreshModalContent } from "./navigation/slide_nav";
import * as pageStack from "./navigation/page_stack";
import { updateCursorIcon } from "./board_cursor";
import { createTemporaryLockObject } from "./board/board_doors";
import { moveObjectsToLayers } from "./board/board_layers";

export function handleFormErrors(data, target) {
  target.find('.alert').remove();
  if (data != null && data.error_message != null) {
    const alert = `<div class="alert alert-danger" role="alert"> \
                     ${data.error_message}
                   </div> `
    target.prepend(alert)
  }
}

export function handleNewContentData(data) {
  if (data == null) return
  if (data == '') return 

  if(data.collapse_element != null){
    $(data.collapse_element).collapse();
  }  
  if(data.remove_stale_pages != null) {
    deleteNonActiveSlideContent();
  }
  if(data.remove_previous_urls != null) {
    pageStack.removeUrls(data.remove_previous_urls);
  }
  if(data.start_url != null) {
    startNav(data.start_url, data.initial_history);
  }
  if(data.next_url != null) {
    if(data.remove_last_from_nav_stack != null ) {
      pageStack.removeCurrent();
    }
    // we remove stale pages here because we're assuming data has changed, otherwise we would have stayed on the front end
    slideTo({ slideToPath: data.next_url, removeStalePages: true, slideSubheader: data.slide_subheader });
  }
  if(data.replace_url != null) {
    replaceModalContent(data.replace_url);
  }
  if(data.go_back != null) {
    if(!isNaN(data.go_back)) {
      // if we're going back a certain count of pages, then remove the pages we want to skip first and then go back
      pageStack.remove(data.go_back - 1);
    }
    // we remove stale pages here because we're assuming data has changed, otherwise we would have stayed on the front end
    slideBack({ removeStalePages: true, slideSubheader: data.slide_subheader });
  }
  if(data.go_back_to != null) {
    pageStack.removeUntil(data.go_back_to);
    // we remove stale pages here because we're assuming data has changed, otherwise we would have stayed on the front end
    slideBack({ removeStalePages: true, slideSubheader: data.slide_subheader });
  }
  if (data.content_updates != null) {
    for (const [selector, content] of Object.entries(data.content_updates)) {
      updatePageContent($(`${selector}`), content, 'full')
    }
  }
  if (data.content_inner_updates != null) {
    for (const [selector, content] of Object.entries(data.content_inner_updates)) {
      updatePageContent($(`${selector}`), content, 'inner')
    }
  }
  if (data.content_appends != null) {
    for (const [selector, content] of Object.entries(data.content_appends)) {
      updatePageContent($(`${selector}`), content, 'append')
    }
  }
  if (data.content_prepends != null) {
    for (const [selector, content] of Object.entries(data.content_prepends)) {
      updatePageContent($(`${selector}`), content, 'prepend')
    }
  }
  if (data.refresh_current_page != null) {
    data.refresh_content_urls = "/";
  }
  if (data.refresh_content_urls != null ) {
    refreshModalContent(data.refresh_content_urls)
  }
  if (data.redirect_to != null) {
    window.location.href = data.redirect_to;
  }

  // custom events
  if (data.refresh_board != null) {
    window.__board.loadCurrentBoard();
  }
  if(data.load_last_roll_details != null) {
    rollEvent.openAllRollDetails();
  }
  if(data.update_chat_user_names != null ) {
    rollEvent.showChatUserNames();
  }
  if('select_character_id' in data ) {  // null is a possible value for select_character_id so we need to check if the property is there 
    window.__board.selectCharacter(data.select_character_id);
    moveObjectsToLayers();
  }
  if(data.selectable_character_ids != null) {
    window.__board.selectableCharacterIds = data.selectable_character_ids;
  }
  if(data.update_character_images != null) {
    $(`.character-${ data.update_character_images.id }-image`).attr('src', data.update_character_images.image_url);
  }
  if(data.update_cursor_icon != null) {
    updateCursorIcon();
  }
  if(data.unlock_attempted != null) {
    
    const wall = window.__board.findObject(data.unlock_attempted);
    if(wall != null && wall.wall == true && wall.type == 'line' && wall.doorState == 'locked') {
      createTemporaryLockObject(wall);
    }
  }
  if(data.http_refresh != null) {
    window.location.reload();
  }

  // needed to remove loading overlay (unless specifically told to leave it - used when action cable does updates)
  if(data.continue_loading != null) { return }
  $('.loading-indicator').addClass('d-none');
}

export function handleAjaxResponse(data, target) {
  handleFormErrors(data, target);
  handleNewContentData(data);
}

$(document).on('ajax:success', function(event) {
  const data = event.detail[0];
  var target = $(event.target);

  $('.dropdown-menu.show').removeClass('show'); //on finished ajax close any open dropdowns as they wont close automatically
  handleAjaxResponse(data, target);
});

