import { Tooltip } from 'bootstrap';

$(document).on('click', '#invite-players', function() {
  const textField = document.querySelector('#invite-players [data-bs-toggle="tooltip"]');
  var tooltip = Tooltip.getOrCreateInstance(textField);
  tooltip.show();
  setTimeout(function() {
    tooltip.hide();
  }, 2000 )
  navigator.clipboard.writeText(textField.value);
});