import { handleAjaxResponse } from "../custom/handle_ajax_response"

function submitChange(element, data) {
  const wrapper = element.closest('.roll-string-form-wrapper');
  const htmlId = wrapper.attr('id');
  const url = wrapper.data("putUrl");
  let input = wrapper.find('input.full-json');

  $.ajax({
    url: url,
    type: 'PUT',
    data: { html_id: htmlId, roll_json: input.val(), ...data},

    success: function (data) {
      input.val(JSON.stringify(data.new_roll_json));
      handleAjaxResponse(data, $(this));
    },
  }); 
}

$(document).on('click', '.remove-modifier', function(event) {
  submitChange($(this), { remove_modifier: $(this).data("value") });
})

$(document).on('click', '.add-modifier', function(event) {
  submitChange($(this), { add_modifier: $(this).data("value") });
})

$(document).on('click', '.ask-player', function(event) {
  submitChange($(this), { ask_player: true });
})

$(document).on('click', '.dont-ask-player', function(event) {
  submitChange($(this), { ask_player: false });
})

$(document).on('change', '.set-base-roll', function(event) {
  submitChange($(this), { base_roll: $(this).find(':selected').val() });
})

$(document).on('click', '.remove-base-roll', function(event) {
  submitChange($(this), { remove_base_roll: true });
})

$(document).on('click', '.add-base-roll', function(event) {
  submitChange($(this), { add_base_roll: true });
})

$(document).on('click', '.allow-negative-flat-bonus', function(event) {
  submitChange($(this), { allow_negative_bonus: true });
})

$(document).on('click', '.remove-flat-bonus', function(event) {
  submitChange($(this), { remove_flat_bonus: true });
})

$(document).on('click', '.add-flat-bonus', function(event) {
  submitChange($(this), { add_flat_bonus: true });
})

$(document).on('change', '.set-flat-bonus', function(event) {
  submitChange($(this), { flat_bonus: $(this).find(':selected').val() });
})

$(document).on('click', '.remove-dex-limit', function(event) {
  submitChange($(this), { remove_dex_limit: true });
})

$(document).on('click', '.add-dex-limit', function(event) {
  submitChange($(this), { add_dex_limit: true });
})

$(document).on('change', '.set-dex-limit', function(event) {
  submitChange($(this), { dex_limit: $(this).find(':selected').val() });
})

$(document).on('input', '.set-advanced-base-roll', function(event) {
  submitChange($(this), { base_roll: $(this).val() });
})