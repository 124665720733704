import isMobile from "../lib/is_mobile";
import { Modal } from 'bootstrap';

$(document).on('click','.open-large-image',function(event){
  if(isMobile()) { return }
  var url = $(this).find('.open-large-image-url').text().trim()
  var image = $('#large-image-modal').find('img');
  image.attr("src","");
  var modal = new Modal(document.getElementById('large-image-modal'))
  modal.show();
  image.attr("src", url);
  event.preventDefault();
  event.stopPropagation();
});
