import Fuse from 'fuse.js'

const fuseOptions = {
  keys: [ 'dataset.searchFilterName' ],
  threshold: 0.3
};


$( document ).on('page_content_updated', function(event, newContent) {
  var fuse;
  var searchField = $(newContent).find('.search-filter')

  if(searchField.length != 0) {
    var searchField = $('.slide-nav-page.active .search-filter');
    var searchItems = $('.slide-nav-page.active [data-search-filter-name]');
    var searchNoItemsMessage = $('.slide-nav-page.active .search-filter-no-results');

    searchField.on('keyup', function(event) {
      searchItems.addClass('d-none')
      var searchItemsArray = $.makeArray(searchItems)
      
      if(fuse == null) {
        fuse = new Fuse(searchItemsArray, fuseOptions);
      }

      const searchTerm = this.value;
      var results;
      if (searchTerm == "") {
        results = searchItemsArray;
      } else {
        results = fuse.search(searchTerm).map((x) => x.item);
      }
      results.forEach(element => {
        element.classList.remove("d-none");
      });

      if(results.length == 0 ) {
        searchNoItemsMessage.removeClass('d-none');
      } else {
        searchNoItemsMessage.addClass('d-none');
      }
    })
  }
});
