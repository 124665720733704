import consumer from "./consumer"
import { handleNewContentData } from "../custom/handle_ajax_response"

function createSubscription (campaignId) {

  consumer.subscriptions.create({
    channel: "ContentUpdateChannel", 
    campaign_id: campaignId }, {
    received(data) {
      handleNewContentData(data);
    }
  });  
};

$( document ).on('campaign_id:set', function(event, campaignId) {
  createSubscription(campaignId);
});
