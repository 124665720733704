export function throttle(func, wait) {
  let timeout = null;
  let lastArgs = null;

  const later = () => {
      if (lastArgs) {
          func.apply(this, lastArgs);
          timeout = setTimeout(later, wait);
          lastArgs = null;
      } else {
          timeout = null;
      }
  };

  return function(...args) {
      if (!timeout) {
          func.apply(this, args);  // Call the function immediately on first call
          timeout = setTimeout(later, wait);
      } else {
          clearTimeout(timeout);
          timeout = setTimeout(later, wait);
          lastArgs = args;  // Store the latest arguments for calling later
      }
  };
}