import { handleAjaxResponse, handleFormErrors } from "../custom/handle_ajax_response"
import { slideTo } from "./navigation/slide_nav";


var cropper;
const imageId = "uncropped-image";
const externalLinkId = "external-link";
const cropImagePathId = "crop-imgage-path";

function slideAndInitCropper(source, contextUrl){
  if (cropper != null) {
    cropper.destroy();
  }
  var cropPath = document.getElementById(cropImagePathId).dataset.cropSlideTo;
  slideTo({ slideToPath: cropPath, slideSubheader: true, callback: function() { initCropper(source, contextUrl) } } );
}

function initCropper(source, contextUrl) {
  var externalLink = $(document.getElementById(externalLinkId))
  if(contextUrl != null) {
    externalLink.show();
    externalLink.attr("href", contextUrl)
  } else {
    externalLink.hide();
  }
  var image = document.getElementById(imageId);
  image.src = source;
  cropper = new Cropper(image, { aspectRatio: 1 / 1, checkCrossOrigin: false });
  $('.loading-indicator').addClass('d-none');
}

$(document).on('click', '[data-image-search-url]', function(event) {
  const imageUrl = $(this).data('imageSearchUrl');
  const contextUrl = $(this).data('contextUrl');
  const campaignId = window.__board.campaignId;
  $('.loading-indicator').removeClass('d-none');

  $.post(`/campaigns/${campaignId}/search_images`, { url: imageUrl }, 
    function (returnedData) {
      if(returnedData.error_message != null) {
        handleFormErrors(returnedData, $(event.target).parent());  // .parent() here is sort of a hack because we want the errors to load into the parent div around the image, not the image itself
        $('.loading-indicator').addClass('d-none');
      } else {
        slideAndInitCropper(returnedData['url'], contextUrl);
      }
    }
  );
})

$(document).on('change', '#character-image-file-input', function(event) {
  const input = event.target;
  if (!(input.files && input.files[0])) return;

  $('.loading-indicator').removeClass('d-none');
  var reader = new FileReader();
  reader.onload = function (e) {
    slideAndInitCropper(e.target.result);
  };
  reader.readAsDataURL(input.files[0]);
});

$(document).on('click', '#character-image-submit', function() {
  var form = document.getElementById(imageId).closest('form');

  $('.loading-indicator').removeClass('d-none');

  cropper.getCroppedCanvas().toBlob(function (blob) {
    var formData = new FormData(form);
    formData.append('image', blob);

    const campaignId = window.__board.campaignId;
    $.ajax(form.action, {
      method: form.method,
      data: formData,
      processData: false,
      contentType: false,
      success: function (data) {
        handleAjaxResponse(data, $(this));
      },
    });
  });
});
