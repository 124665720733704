const collapseForAdjustment = function() {
  $('.toggle-wallet .action').addClass('disabled');
  $('.toggle-wallet:not(.down-arrow)').collapse('show');
  $('.toggle-wallet.down-arrow').collapse('hide');
  $('.currency-add-remove').collapse('hide');
  $('.currency-save').collapse('show');
}

$(document).on('click', '.add-to-wallet', function(e) {
  if($('.toggle-wallet.down-arrow').hasClass('show')) {
    $('.currency-option-0').addClass('show');  
  } else {
    $('.currency-option-0').collapse('show'); 
  }
  collapseForAdjustment();  
  $('.currency-remove').collapse('hide');
  $('.currency-add').collapse('show');
  e.preventDefault();
  e.stopPropagation();
})

$(document).on('click', '.remove-from-wallet', function(e) {
  collapseForAdjustment();
  $('.currency-remove').collapse('show');
  $('.currency-add').collapse('hide');
  e.preventDefault();
  e.stopPropagation();
  })


$(document).on('click', '.currency-cancel', function(e) {
  $('.toggle-wallet .action').removeClass('disabled');
  $('.currency-add').collapse('hide');
  $('.currency-remove').collapse('hide');
  $('.currency-add-remove').collapse('show');
  $('.currency-save').collapse('hide');
  $('.currency-option-0').collapse('hide');
  $('.add-to-wallet-link').collapse('hide');
  $('.add-to-wallet-section').collapse('hide');
  e.preventDefault();
  e.stopPropagation();
})

$(document).on('click', '#wallet-content input', function(e) {
  e.preventDefault();
  e.stopPropagation();
})

$(document).on('click', '.currency-save input', function(e) {
  Rails.fire($(this).closest('form')[0], 'submit');
  e.preventDefault();
  e.stopPropagation();
})
