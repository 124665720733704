function slideNavPageTemplate(path, loaded, content = null, classes = '') {
  return `<div class="slide-nav-page no-scrollbars ${classes}" data-slide-page="${path}" data-slide-loaded="${loaded}">${content}</div>`
}

function addNewPageTemplateToLoad(path, slideFromPath, direction) {
  // this adds a new slide page for any slide navs without a template, which should really just be the body and any navs within navs
  const newElementToLoad = slideNavPageTemplate(path, false);
  var toLoadSlideFromPage = $('.slide-nav').not('[data-with-content-template]').find(`[data-slide-page='${slideFromPath}']`)
  if(direction == 'left') {
    $(newElementToLoad).insertBefore(toLoadSlideFromPage);  
  } else {
    $(newElementToLoad).insertAfter(toLoadSlideFromPage);
  }
}

function addContentTemplate(path, atElement, direction, classes ='') {
  var slideNav = atElement.closest('.slide-nav');
  var missingContentTemplate = slideNav.find('.slide-nav-content-template').html();
  const newMissingElement = slideNavPageTemplate(path, true, missingContentTemplate, classes);
  if(direction == 'left') {
    $(newMissingElement).insertBefore(atElement);
  } else {
    $(newMissingElement).insertAfter(atElement);
  }
}

function addHeaderTemplate(path, slideFromPath, direction) {
  var atElement = $(`.slide-panel-header [data-slide-page='${slideFromPath}']`)
  addContentTemplate(path, atElement, direction)
}
function addSubheaderTemplate(path, slideFromPath, direction) {
  var atElement = $(`.slide-panel-subheader [data-slide-page='${slideFromPath}']`)
  addContentTemplate(path, atElement, direction)
}
function addFooterTemplate(path, slideFromPath, direction) {
  var atElement = $(`.slide-panel-footer [data-slide-page='${slideFromPath}']`)
  addContentTemplate(path, atElement, direction)
}

export default function addUnloadedSlidePageTemplates(slideToPathWithoutParams, slideFromPath, direction, slideSubheader, slidingNavWithinNav) {
  addNewPageTemplateToLoad(slideToPathWithoutParams, slideFromPath, direction);
  if(!slidingNavWithinNav) {
    addSubheaderTemplate(slideToPathWithoutParams, slideFromPath, direction)
    if(!slideSubheader) {
      addHeaderTemplate(slideToPathWithoutParams, slideFromPath, direction)
    }
    addFooterTemplate(slideToPathWithoutParams, slideFromPath, direction)
  }

}