import { handleAjaxResponse } from "./handle_ajax_response"

var target, touchStartY, touchStartX, droppable, droppableOriginalBorder, droppableTargets;

var campaignId;
$( document ).on('campaign_id:set', function(event, cId) { campaignId = cId });

const touchMoveHandler = function(e) {
  var touchCurrentX = e.touches[0].clientX;
  var touchCurrentY = e.touches[0].clientY;

  var touchXDelta = touchStartX - touchCurrentX;
  var touchYDelta = touchStartY - touchCurrentY;

  target.css('right', `${touchXDelta}px`);
  target.css('bottom', `${touchYDelta}px`);

  droppableTargets = $(document.elementsFromPoint(touchCurrentX, touchCurrentY)).filter('.droppable');
  droppable.css('border', droppableOriginalBorder);
  droppableTargets.css('border', '2px solid #007bff')

  e.preventDefault();
};

const touchEndHandler = function(e) {

  if (droppableTargets != null && droppableTargets.length !== 0) {
    const id = target.data('id');
    const ability_score_name = droppableTargets.first().data('abilityScoreName');
    $.ajax({
      type: "PUT",
      url: `/campaigns/${campaignId}/ability_score_rolls/${id}/mark_used?ability_score_name=${ability_score_name}`,
      success: function (data) {
        handleAjaxResponse(data, $(this));
      },
    });
    e.preventDefault();
  } else {
    target.css('right', '0');
    target.css('bottom', '0');  
  }

  document.removeEventListener('touchmove', touchMoveHandler);
  document.removeEventListener('touchend', touchEndHandler);
};

$(document).on('touchstart', '[draggable="true"]', function(e) {
  target = $(this);
  touchStartY = e.touches[0].clientY;
  touchStartX = e.touches[0].clientX;
  droppable = $('.droppable');
  droppableOriginalBorder = droppable.css('border')
  document.addEventListener('touchmove', touchMoveHandler, { passive:false });
  document.addEventListener('touchend', touchEndHandler);
})
