import * as requests from "./board_requests"

var board;
var base = fabric.Canvas.prototype;

$( document ).on('board:created', function(event, b) { board = b; });

var mylatesttap;
base.handleDoubleTapOnMouseUp = function(options) {
  if(options.e.touches == null) { return }    // only interested in touches, not clicks
  if(options.e.touches.length > 1) { return } // dont count pinches as a possible double tap
  if(options.target == null) { return }       // not interested if there is no target

  var now = new Date().getTime();
  var timesince = now - mylatesttap;
  if((timesince < 700) && (timesince > 0)){
    board.mouseUpPerformed = true;
    requests.loadObjectShow(options);
  }
  mylatesttap = new Date().getTime();
}
