

$(document).ready(function() {
  const startHeight = $('#event-sidebar').css('height');
  const expandHeight = '65vh';
  const collapseHeight = $('#event-sidebar-label').css('height');
  var history = $('#event-sidebar');
  var touchStartY;
  var done = false;

  const animateHeight = function(height) {
    history.animate({ height: height });
  }  

  const expandHistory = function() {
    if(done) { return }
    if (history.css('height') == startHeight) {
      animateHeight(expandHeight);
      $('#event-form-mobile').collapse('show');
    } else if (history.css('height') == collapseHeight) {
      animateHeight(startHeight);
    }
    done = true;
  }
  
  const collapseHistory = function() {
    if(done) { return }
    if (history.css('height') == startHeight) {
      animateHeight(collapseHeight);
    } else if (history.css('height') != collapseHeight) { // workaround to check for expandHeight
      animateHeight(startHeight);
      $('#event-form-mobile').collapse('hide');
    }
    done = true;
  }

  const touchMoveHandler = function(e) {
    var touchMoveY = e.touches[0].clientY
    var touchDelta = touchStartY - touchMoveY;
    if (touchDelta >= 0) {
      expandHistory();
    } else {
      collapseHistory();
    }
    e.preventDefault();
  };

  const touchEndHandler = function() {
    if (history.css('height') == startHeight || history.css('height') == collapseHeight) {
      expandHistory();
    } else { // ie height is expandHeight
      collapseHistory();
    }
    done = false;
    document.removeEventListener('touchmove', touchMoveHandler);
    document.removeEventListener('touchend', touchEndHandler);
  };

  $('#event-sidebar-label').on('touchstart', function(e) {
    done = false;
    touchStartY = e.touches[0].clientY;
    document.addEventListener('touchmove', touchMoveHandler, { passive:false });
    document.addEventListener('touchend', touchEndHandler);
  })
});