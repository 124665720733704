import Fuse from 'fuse.js'

var itemList;
var fuse;

const fuseOptions = {
  keys: [
    "name",
  ],
  threshold: 0.3
};

var campaignId;
var characterId;
$( document ).on('campaign_id:set', function(event, cId) { campaignId = cId });

const updateDropdownContent = function(term, items) {
  let newHtml = ``;
  items.forEach(function(item) {
    newHtml = newHtml +   
    `<a class="dropdown-item" data-remote="true" rel="nofollow" data-method="post" href="/campaigns/${campaignId}/characters/${characterId}/items?item_template_id=${item.id}"> ${item.name}</a>`
  })
  if(term != '') {
    newHtml = newHtml + 
    `<a class="dropdown-item text-primary" data-remote="true" rel="nofollow" data-method="post" href='/campaigns/${campaignId}/items?item[name]=${term}&add_to_character=${characterId}' > Create '${term}'</a>`
  }
  $('#item-search-menu').html(newHtml)
}

const fuseSearch = function(term) {
  let results;
  if(term == '') {
    results = itemList;
  } else {
    results = fuse.search(term).map((x) => x.item);
  }
  updateDropdownContent(term, results);
}

$(document).on('focus', '#item-search', function(event) { 

  characterId = this.dataset.characterId;
  $.get( `/campaigns/${campaignId}/items/search_json`, function( data ) {
    itemList = data.items;
    fuse = new Fuse(itemList, fuseOptions);
  });

});


$(document).on('mouseup', '#item-search', function(event) {
  event.preventDefault();
  event.stopPropagation();
  $('#item-search-menu').addClass('show');
});

$(document).on('keydown', '#item-search', function(event) {
  if(!$('#item-search-menu').hasClass('show')) {
    $('#item-search-menu').addClass('show');
  }
})


$(document).on('keyup', '#item-search', function(event) {
  fuseSearch(this.value)

  // if the user presses the down arrow, focus the first item in the dropdown
  if (event.keyCode === 40) {
    $('#item-search-menu .dropdown-item:first').focus()
  }
})

$(document).on('keyup', '#item-search-menu', function(event) {  
  // if the user presses the up arrow while the first item in the dropdown is focused, focus the search input
  if (event.keyCode === 38) {
    if ($('#item-search-menu .dropdown-item:first').is(':focus')) {
      var itemSearch = $('#item-search');
      itemSearch.focus();
      var currentVal = itemSearch.val();
      itemSearch.val('').val(currentVal);
    }
  }
})



