
export default function mouseOrTouchPosition(event) {
  var clientX, clientY;
  if (event.touches != null) {
    clientX = event.touches[0].clientX;
    clientY = event.touches[0].clientY;
  } else {
    clientX = event.clientX;
    clientY = event.clientY;
  }
  return { x: clientX, y: clientY }  
}
