import { setBoardTool } from "./board_controls"
import { addPin } from "./board_pins"
import { addLight } from "./board_lights"
import { eraseSelectedObject } from "./board_eraser"
import { clickedWallLine, eraseWallLine } from "./board_walls_eraser"
import { setObjectOpacityAndSelectability } from "./board_object_opacity_and_selectability"
import * as requests from "./board_requests"
import isMobile from "../../lib/is_mobile"
import uniqueId from "../../lib/unique_id"
import { resetDoorObject, setDoorState } from "./board_doors"
import { setWallType } from "./board_walls"
import { refreshModalContent } from "../navigation/slide_nav"

var board;
var base = fabric.Canvas.prototype;
var mousedownTimer;
var target;
var originalLockMovementX;
var originalLockMovementY;
var originalOptions;

$( document ).on('board:created', function(event, b) { board = b; });

export const getTarget = function(options) {
  if(options.target != null && options.target.door == true) { return options.target.associatedWall }
  if(options.target != null && options.target.selectable == true) { 
    return options.target 
  } else {
    return getClickedWallIfClickable(options);
  }
}

const toolMenuItem = function(id, label) {
  return `<div class="dropdown-item contextual-menu-tool-select cursor" data-tool-id="${id}">${label}</div>`
}

const editTargetMenuHtml = function(label) {
  return `<div class="dropdown-item contextual-menu-edit-object cursor" >${label}</div>`
}

const tokenStackingOrderMenuHtml = function() {
  return `<div class="dropdown-item contextual-menu-token-stacking-order cursor" >Adjust stacking order</div>`
}

const imageStackingOrderMenuHtml = function() {
  return `<div class="dropdown-item contextual-menu-image-stacking-order cursor" >Adjust stacking order</div>`
}

const removeTargetMenuHtml = function(label) {
  return `<div class="dropdown-item contextual-menu-erase-object cursor" >${label}</div>`
}

const hideTargetMenuHtml = function() {
  return `<div class="dropdown-item contextual-menu-hide-object cursor" >Hide from players</div>`
}

const showTargetMenuHtml = function() {
  return `<div class="dropdown-item contextual-menu-show-object cursor" >Show to players</div>`
}

const makeTransparentMenuHtml = function() {
  return `<div class="dropdown-item contextual-menu-make-transparent cursor" >Make transparent</div>`
}

const makeOpaqueMenuHtml = function() {
  return `<div class="dropdown-item contextual-menu-make-opaque cursor" >Make opaque</div>`
}

const addDoorMenuHtml = function() {
  return `<div class="dropdown-item contextual-menu-add-door cursor" >Add Door</div>`
}

const removeDoorMenuHtml = function() {
  return `<div class="dropdown-item contextual-menu-remove-door cursor" >Remove Door</div>`
}

const lockDoorMenuHtml = function() {
  return `<div class="dropdown-item contextual-menu-lock-door cursor" >Lock Door</div>`
}

const unlockDoorMenuHtml = function() {
  return `<div class="dropdown-item contextual-menu-unlock-door cursor" >Unlock Door</div>`
}

const lockPositionTargetMenuHtml = function() {
  return `<div class="dropdown-item contextual-menu-lock-position-object cursor" >Lock position</div>`
}

const unlockPositionTargetMenuHtml = function() {
  return `<div class="dropdown-item contextual-menu-unlock-position-object cursor" >Unlock position</div>`
}

const getClickedWallIfClickable = function(options) {
  if(!board.currentUserIsGm) { return }
  if(!board.isWallingMode && !board.isErasingMode && !board.gmLayerVisible) { return }
  return clickedWallLine(originalOptions);
}

const showHideMenuItem = function(target) {
  if(target.pin == true|| target.token == true || (target.type == 'image' && target.background != true) || target.shapeType != null ) {
    if(target.showPlayers == true)  {
      return hideTargetMenuHtml();
    } else {
      return showTargetMenuHtml();
    }
  }
}
const lockUnlockPosition = function(target) {
  if((target.type == 'image' && target.background != true || target.shapeType != null)) {
    if(target.lockPosition == false)  {
      return lockPositionTargetMenuHtml();
    } else {
      return unlockPositionTargetMenuHtml();
    }
  }
}

const playerEditTargetMenuItem = function() {
  if(originalOptions.target == null) { return }
  if(originalOptions.target.selectable != true) { return }
  if(originalOptions.target.token == true) { return editTargetMenuHtml("Character Sheet") }
  if(originalOptions.target.pin == true) { return editTargetMenuHtml("Pin description") }
  
}

const changeWallTransparencyMenuItem = function(wall) {
 
  if (wall == null) { return }
  if (wall.opacity == 0) { return }

  if(wall.transparent != true) {
    return makeTransparentMenuHtml()
  } else {
    return makeOpaqueMenuHtml()
  }
}

const lockUnlockMenuItem = function(wall) {
  if (wall == null) { return }
  if (wall.opacity == 0) { return }

  if(wall.doorState == 'closed') {
    return lockDoorMenuHtml()
  } else if(wall.doorState == 'locked') {
    return unlockDoorMenuHtml()
  }
}

const changeWallDoorMenuItem = function(wall) {
  if (wall == null) { return }
  if (wall.opacity == 0) { return }

  if(wall.doorState == null) {
    return addDoorMenuHtml()
  } else {
    return removeDoorMenuHtml()
  }
}

const undoMenuItem = function() {
  if(board.undoClickId == null) { return }
  if(board.undoText == null) { return }
  return `<div class="dropdown-item contextual-menu-undo cursor" data-click-id="${board.undoClickId}">Undo ${board.undoText}</div>`
}

const menuItemsForTarget = function(target) {
  if(target == null) { return }
  if(target.token == true) {
    return [
      editTargetMenuHtml("Character Sheet"),
      showHideMenuItem(target),
      tokenStackingOrderMenuHtml(),
      `<div class="dropdown-divider"></div>`,
      removeTargetMenuHtml("Remove Token")
    ]
  } else if(target.lightSource == true) {
    return [
      editTargetMenuHtml("Edit Light"),
      removeTargetMenuHtml("Remove Light")
    ]
  } else if(target.pin == true) {
    return [
      editTargetMenuHtml("Edit Pin"),
      showHideMenuItem(target),
      removeTargetMenuHtml("Remove Pin")
    ]
  } else if(target.wall == true && target.type == "circle") {
    return [
      removeTargetMenuHtml("Remove Wall")
    ]
  } else if(target.wall == true && target.type == "line") {
    if(target.doorState != null) {
      return [
        editTargetMenuHtml("Edit Door"),
        lockUnlockMenuItem(target),
        `<div class="dropdown-divider"></div>`,
        changeWallDoorMenuItem(target)
      ]
    }
    return [
      editTargetMenuHtml("Edit Wall"),
      `<div class="dropdown-divider"></div>`,
      changeWallDoorMenuItem(target),
      changeWallTransparencyMenuItem(target),
      `<div class="dropdown-item contextual-menu-erase-wall-line cursor" >Remove Wall</div>`
    ]
  } else if ((target.type == 'image' && target.background != true) || target.shapeType != null) {
    let label = "Image"
    if (target.shapeType != null) {
      label = "Shape"
    }
    return [
      editTargetMenuHtml(`Edit ${label}`),
      `<div class="dropdown-divider"></div>`,
      showHideMenuItem(target),
      lockUnlockPosition(target),
      imageStackingOrderMenuHtml(),
      `<div class="dropdown-divider"></div>`,
      removeTargetMenuHtml(`Remove ${label}`)
    ]
  }
}
const menuItems = function(options) {
  var items;
  
  if(!board.currentUserIsGm) {
    var  switchToolItems = [
      toolMenuItem('tool-move', 'Move Tool'),
      toolMenuItem('tool-draw', "Highlight Tool"),
      toolMenuItem('tool-ruler', "Ruler Tool") 
    ]
    var editMenuItem = playerEditTargetMenuItem();
    if(editMenuItem != null) {
      items = [editMenuItem];
    } else {
      items = switchToolItems;
    }
  } else {
    var target = getTarget(options);

    if(target != null) {
      items = menuItemsForTarget(target);
    } else {
      items = [
        `<div class="dropdown-item cursor contextual-menu-add-pin">Add Pin</div>`,
        `<div class="dropdown-item cursor contextual-menu-add-light">Add Light</div>`,
        `<div class="dropdown-item cursor contextual-menu-add-tokens">Add Tokens</div>`,
        `<div class="dropdown-divider"></div>`,
        toolMenuItem('tool-move', 'Move Tool'),
        toolMenuItem('tool-draw', "Highlight Tool"),
        toolMenuItem('tool-ruler', "Ruler Tool"),
        toolMenuItem('tool-eraser', 'Eraser Tool'),
        toolMenuItem('tool-walls', 'Walls Tool'),
      ]
      if (board.undoClickId != null) {
        items = [undoMenuItem(), `<div class="dropdown-divider"></div>`].concat(items)
      }
    }
  }
  return items;
}

const addMenuContent = function(options) {
  $('#contextual-menu').html ( 
    menuItems(options).join('')
  )
}

const closeMenu = function(e) {
  $('#contextual-menu').removeClass("show");
  document.removeEventListener('click', closeMenu);
  if (target != null) {
    target.lockMovementX = originalLockMovementX;
    target.lockMovementY = originalLockMovementY;
  }
  board.contextualMenuOpen = false;
  originalOptions = null;
};


export const openMenu = function(options) {
  
  board.contextualMenuOpen = true;
  board.mouseUpPerformed = true;

  // we need to trigger a mouse up event so that if the user is in highlight mode, the highlight will stop drawing
  var event = new MouseEvent("mouseup", {
    bubbles: true,
    cancelable: true,
    view: window
  });
  board.upperCanvasEl.dispatchEvent(event);

  // lock movement on any item we're clicking on so it doesn't get dragged once the menu is open
  target = options.target;
  if (target != null) {
    originalLockMovementX = target.lockMovementX;
    originalLockMovementY = target.lockMovementY;
    target.lockMovementX = true;
    target.lockMovementY = true;
  }

  addMenuContent(options)

  $('#contextual-menu').addClass("show");
  $('#contextual-menu').addClass("update-cursor-location-menu");
  $('#contextual-menu').css('left', `${Math.round(options.e.clientX)}px`);
  $('#contextual-menu').css('top', `${Math.round(options.e.clientY)}px`);
  document.addEventListener('click', closeMenu);
}

export const setOriginalOptionsForContextualMenu = function(options) {
  originalOptions = options;
}

base.handleMouseDownForContextualMenu = function(options) {
  setOriginalOptionsForContextualMenu(options);
  mousedownTimer = setTimeout(function() {openMenu(options) }, 500)
}

$(document).on('touchstart', function(event) {
  // for now, dont do context menu on touch screens - it was too hard to get all the logic / gestures working correctly
  clearTimeout( mousedownTimer );
  if(board != null) {
    closeMenu(event);
  }
});


base.handleMouseMoveForContextualMenu = function(options) {
  clearTimeout( mousedownTimer );
}

base.handleMouseUpForContextualMenu = function(options) {
  clearTimeout( mousedownTimer );
}

$(document).on('mouseup', '.contextual-menu-tool-select', function(event) {
  var toolId = $(this).data()['toolId'];
  setBoardTool(toolId);
});

$(document).on('mouseup', '.contextual-menu-add-pin', function(event) {
  addPin(originalOptions);
});

$(document).on('mouseup', '.contextual-menu-add-light', function(event) {
  addLight(originalOptions);
});

$(document).on('mouseup', '.contextual-menu-edit-object', function(event) {
  requests.loadObjectShow(originalOptions);
});

$(document).on('mouseup', '.contextual-menu-token-stacking-order', function() {
  requests.loadTokenStackingOrder();
});

$(document).on('mouseup', '.contextual-menu-image-stacking-order', function() {
  requests.loadImageStackingOrder();
});

$(document).on('mouseup', '.contextual-menu-erase-object', function(event) {
  board.currentClickId = uniqueId()  // need to set the current click id so the click can be undone
  eraseSelectedObject(originalOptions);
});

$(document).on('mouseup', '.contextual-menu-erase-wall-line', function(event) {
  board.currentClickId = uniqueId()  // need to set the current click id so the click can be undone
  eraseWallLine(originalOptions);
});

$(document).on('mouseup', '.contextual-menu-add-tokens', function(event) {
  requests.loadNewBoardObject();
});

const updateObjectOpacityAndSelectability = function(object) {
  setObjectOpacityAndSelectability(object, board);
  if(object.indicatorObjects != null) {
    object.indicatorObjects.forEach(indicatorObject => {
      setObjectOpacityAndSelectability(indicatorObject, board)
    });
  }
  requests.updateRequest(object);
  board.renderAll();
};

$(document).on('mouseup', '.contextual-menu-show-object', function(event) {
  originalOptions.target.showPlayers = true;
  updateObjectOpacityAndSelectability(originalOptions.target);
});

$(document).on('mouseup', '.contextual-menu-hide-object', function(event) {
  originalOptions.target.showPlayers = false;
  updateObjectOpacityAndSelectability(originalOptions.target);
});

$(document).on('mouseup', '.contextual-menu-lock-position-object', function(event) {
  originalOptions.target.lockPosition = true;
  updateObjectOpacityAndSelectability(originalOptions.target);
});

$(document).on('mouseup', '.contextual-menu-unlock-position-object', function(event) {
  originalOptions.target.lockPosition = false;
  updateObjectOpacityAndSelectability(originalOptions.target);
});

$(document).on('mouseup', '.contextual-menu-make-opaque', function(event) {
  setWallType(getTarget(originalOptions), 'regular');
});

$(document).on('mouseup', '.contextual-menu-make-transparent', function(event) {
  setWallType(getTarget(originalOptions), 'transparent');
});

$(document).on('mouseup', '.contextual-menu-add-door', function(event) {
  setWallType(getTarget(originalOptions), 'door');
});

$(document).on('mouseup', '.contextual-menu-remove-door', function(event) {
  setWallType(getTarget(originalOptions), 'regular');
});

$(document).on('mouseup', '.contextual-menu-lock-door', function(event) {
  setDoorState(getTarget(originalOptions), 'locked')
});

$(document).on('mouseup', '.contextual-menu-unlock-door', function(event) {
  setDoorState(getTarget(originalOptions), 'closed')
});


$(document).on('mouseup', '.contextual-menu-undo', function(event) {
  requests.undo($(this).data('clickId'));
  board.undoClickId = null;
  board.undoText = null;
});
