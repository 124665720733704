export const angleToPoint = function(startX, startY, endX, endY) {
  return Math.atan2((endY - startY), (endX - startX));
}

export const linesForSquare = function(x1, y1, x2, y2) {
  return  [
    { x1: x1, y1: y1, x2: x2, y2: y1 },
    { x1: x2, y1: y1, x2: x2, y2: y2 },
    { x1: x2, y1: y2, x2: x1, y2: y2 },
    { x1: x1, y1: y2, x2: x1, y2: y1 }
  ]
}

export const pointsOfSightPolygon = function(point, wallLines, wallPoints) {
  var points = [];
  var sightLines = buildSightLines(point, wallPoints);
  sightLines.forEach(function(sightLine) {
  
    var closestPoint = { u: Infinity };
    wallLines.forEach(function(wallLine) {
      const intersection = sightLineIntersectsWallAt(sightLine, wallLine);
      if (intersection && intersection.u < closestPoint.u) {
        closestPoint = intersection;
      }
    })
    points.push(closestPoint);
  
  })
  points = points.sort((a, b) => angleToPoint(point.x, point.y, a.x, a.y) - angleToPoint(point.x, point.y, b.x, b.y))
  return [points, sightLines]
}  

const lineFromAngle = function(startX, startY, angle) {
  const directionX = Math.cos(angle);
  const directionY = Math.sin(angle);
  return { x1: startX, y1: startY, x2: startX + directionX, y2: startY + directionY }
}

const buildSightLine = function(startX, startY, endX, endY) {
  // a sightLine contains the original line and two slightly offset lines so we'll connect with anything 'behind' the original line if it's a corner
  const originalLine = { x1: startX, y1: startY, x2: endX, y2: endY };
  const originalAngle = angleToPoint(startX, startY, endX, endY);
  const offsetLine1 = lineFromAngle(startX, startY, originalAngle + 0.001);
  const offsetLine2 = lineFromAngle(startX, startY, originalAngle - 0.001);
  return [originalLine, offsetLine1, offsetLine2];
}

const calculateIntersectionPoint = function(lineA, lineB) {
  const x1 = lineB.x1;
  const y1 = lineB.y1;
  const x2 = lineB.x2;
  const y2 = lineB.y2;

  const x3 = lineA.x1;
  const y3 = lineA.y1;
  const x4 = lineA.x2;
  const y4 = lineA.y2;

  const den = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);
  if (den == 0) {
    return {x: null, y: null, t: null, u: null};
  }

  const t = ((x1 - x3) * (y3 - y4) - (y1 - y3) * (x3 - x4)) / den;
  const u = -((x1 - x2) * (y1 - y3) - (y1 - y2) * (x1 - x3)) / den;

  const x = x1 + t * (x2 - x1);
  const y = y1 + t * (y2 - y1);

  return { x: x, y: y, t: t, u: u }
}

export const linesIntersectAt = function(lineA, lineB) {
  const { x: x, y: y, t: t, u: u } = calculateIntersectionPoint(lineA, lineB)
  if (x == null || y == null) { return }


  // for regular lines we care about the maximum value of u
  if (t > 0 && t < 1 && u > 0 && u < 1) {
    return { x: x, y: y, t: t, u: u }
  } else {
    return;
  }
}

export const sightLineIntersectsWallAt = function(sightLine, wallLine) {
  const { x: x, y: y, t: t, u: u } = calculateIntersectionPoint(sightLine, wallLine)
  if (x == null || y == null) { return }

  // for sightlines we don't care about the max value of u because they extend forever
  if (t >= 0 && t <= 1 && u > 0) {
    return { x: x, y: y, t: t, u: u }
  } else {
    return;
  }
}

export const buildSightLines = function(startPoint, endPoints) {
  var sightLines = []
  endPoints.forEach(function(endPoint) {
    sightLines = sightLines.concat(buildSightLine(startPoint.x, startPoint.y, endPoint.left, endPoint.top));
  })
  return sightLines
}

export const objectSightLineStartPoints = function(object, wallLines) {
  const vantagePointCount = 16;
  const vantageOffset = .8;
  const radius = (object.scaleWidth / 2) * vantageOffset;
  const startAngle = - Math.PI;
  const increment = (2 * Math.PI) / vantagePointCount;
  var currentAngle = startAngle;
  const objectMiddleX = object.left + (object.scaleWidth / 2);
  const objectMiddleY = object.top + (object.scaleHeight / 2);

  var points = [];
  for(var i = 1; i <= vantagePointCount; i++) {
    const angleX = radius * Math.cos(currentAngle);
    const angleY = radius * Math.sin(currentAngle);
    var pointX = objectMiddleX + angleX;
    var pointY = objectMiddleY + angleY;
    currentAngle = currentAngle + increment;
    // if we cross any walls going from the vantage point to the center of the object, skip the vantage point (prevents accidentally seeing on the other side of walls)
    const overlappingWall = wallLines.find(function(wallLine) {
      const intersectPoint = linesIntersectAt({ x1: pointX, y1: pointY, x2: objectMiddleX, y2: objectMiddleY }, wallLine)
      if(intersectPoint != null){ return true }
    })
    if(overlappingWall) { continue }
    points.push({ x: pointX, y: pointY })
  }
  return points;
}
