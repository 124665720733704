
import { slideTo, slideBack, replaceModalContent, closeNav, startNav} from "./slide_nav";

$(document).on('click','.close-nav',function(){
  closeNav();
});

$(document).on('click','[data-start-nav]',function(){
  startNav(this.dataset.startNav)
});

$(document).keydown(function(e) {
  if (e.key === "Escape") {
    var openModal = $('.modal.show');
    if(openModal.length == 0) {
      closeNav();
    } else {
      openModal.modal('hide')
    }
  }

});

$(document).on('change', '.slide-replace-on-change', function(event) {
  // Dont love this but we're recreating the form data here.  Just makes it easier to make the forms in HTML
  const formData = new FormData(this);
  const params = new URLSearchParams(formData);
  
  $('.loading-indicator').removeClass('d-none');
  replaceModalContent(`${this.action}?${params}`)
})

$(document).on('change', '.submit-on-change', function(event) {
  if(this.dataset['remote'] == 'true') {
    Rails.fire(this, 'submit');
  } else {
    this.submit();
  }
})

$(document).on('keydown', '.submit-on-enter', function(e){
  if(e.which == 13 && !e.shiftKey){
    Rails.fire($(this).closest('form')[0], 'submit');
   }
});

$(document).on('click', '.single-click-select', function(event) {
  var form = $(this).closest('form')
  form.find('.single-click-select-field').val($(this).data('fieldValue'))
  Rails.fire(form[0], 'submit');
})

$(document).on('click', '.step-up', function(event) {
  var field = $(this).closest('.input-group').find('input[type=number]')
  field[0].stepUp();
  field.change();
})

$(document).on('click', '.step-down', function(event) {
  var field = $(this).closest('.input-group').find('input[type=number]')
  field[0].stepDown();
  field.change();
})

$(document).on('ajax:send', function(event) {
  if(event != null && event.target != null && $(event.target).hasClass('skip-loading-indicator')) { return }
  $('.loading-indicator').removeClass('d-none');
});

$(document).on('ajax:success', function() {
  $('.loading-indicator').addClass('d-none');
});

$(document).on('click', '[data-slide-to]:not(.disabled)', function(event) {
  const slideToPath = $(this).data('slideTo');
  var direction = $(this).data('slideDirection');
  var slideMainNavOverride = $(this).data('slideMainNavOverride');
  slideTo({ slideToPath: slideToPath, direction: direction, slideMainNavOverride: slideMainNavOverride, clickTarget: event.target });
})

$(document).on('click', '[data-slide-subheader-to]:not(.disabled)', function(event) {
  const slideToPath = $(this).data('slideSubheaderTo');
  var slideMainNavOverride = $(this).data('slideMainNavOverride');
  slideTo({ slideToPath: slideToPath, clickTarget: event.target, slideMainNavOverride: slideMainNavOverride, slideSubheader: true });
})

$(document).on('click', '[data-slide-replace]:not(.disabled)', function(event) {
  const slideToPath = $(this).data('slideReplace');
  replaceModalContent(slideToPath);
})

$(document).on('click', '.slide-back-stack', function(event) {
  slideBack({clickTarget: event.target});
})

$(document).on('click', '.slide-subheader-back-stack', function(event) {
  slideBack({clickTarget: event.target, slideSubheader: true});
})


$(document).on('click', '[data-start-nav]', function(e) {
  e.preventDefault();
  e.stopPropagation();  
})

