import uniqueId from "../../lib/unique_id"
import { resizeDoorToWall } from "./board_doors";

const initialConditionIndicatorAngle = 1.25 * Math.PI;
const conditionIndicatorAngleStep = .2 * Math.PI;

var board;
$( document ).on('board:created', function(event, b) { board = b });

var combatIndicatorObjects = [];
var combatIndicatorAnimationRunning = false;
var animationDirection = 'decreasing';
var timeoutStep = 20;
var opacityStep = 0.04;

export const removeCombatIndicatorObjects = function(token, board) {
  board.remove(token.combatIndicatorObject);
  board.remove(token.combatIndicatorObjectBackground);
  combatIndicatorObjects = combatIndicatorObjects.filter(item => (item !== token.combatIndicatorObjectBackground && item !== token.combatIndicatorObject))
  token.combatIndicatorObject = null;    
  token.combatIndicatorObjectBackground = null;    
}

const combatIndicatorAnimationStep = function() {

  if(combatIndicatorObjects.length == 0) {
    combatIndicatorAnimationRunning = false;
  } else {
    const opacity = combatIndicatorObjects[0].opacity
    if (opacity <= 0) {
      animationDirection = 'increasing';
    } else if (opacity >= 1) {
      animationDirection = 'decreasing';
    }
    if(animationDirection == 'decreasing') {
      const nextOpacity = Math.max(opacity - opacityStep, 0);
      combatIndicatorObjects.forEach(object => object.set({opacity: nextOpacity}));
    } else if (animationDirection == 'increasing') {
      const nextOpacity = Math.min(opacity + opacityStep, 1);
      combatIndicatorObjects.forEach(object => object.set({opacity:  nextOpacity}));
    }
    board.renderAll();

    setTimeout(combatIndicatorAnimationStep, timeoutStep);
  }  
}


const startCombatIndicatorAnimation = function() {
  if(combatIndicatorAnimationRunning == true) { return }
  if(combatIndicatorObjects.length == 0) { return }
  combatIndicatorAnimationRunning = true;
  combatIndicatorAnimationStep()
}

const sharedIndicatorProperties = {
  originX:'center',
  originY:'center', 
  indicator: true,
}

const createCombatIndicatorObjects = function(token) {

  var path = $(`#select-combatants-icon`).find('[data-fa-i2svg] path').attr('d');

  const radius = (token.scaleHeight / 2) + token.strokeWidth / 2 ;
  const top = token.top + radius;
  const left = token.left + radius;

  const offsetTop = top - token.top;
  const offsetLeft = left - token.left;

  var indicatorObject = new fabric.Path(path, {
    offsetTop: offsetTop,
    offsetLeft: offsetLeft,
    top: top, 
    left: left, 
    fill: 'white',
    associatedToken: token,
    uid: uniqueId(),
    ...sharedIndicatorProperties
   });

  var backgroundObject = new fabric.Circle({
    offsetTop: offsetTop,
    offsetLeft: offsetLeft,
    top: top, 
    left: left, 
    strokeWidth: 1,
    radius: 23,
    fill: '#485fc7',
    stroke: 'white',
    associatedToken: token,
    uid: uniqueId(),
    ...sharedIndicatorProperties
  });
   indicatorObject.scale(0.047);
   board.addObject(backgroundObject);
   board.addObject(indicatorObject);
   token.combatIndicatorObject = indicatorObject; 
   token.combatIndicatorObjectBackground = backgroundObject; 
   combatIndicatorObjects.push(indicatorObject, backgroundObject);
   startCombatIndicatorAnimation();
}



const createConditionIndicatorObject = function(indicator, token, index) {
  var path = $(`.condition-icon.${indicator.slug}`).find('[data-fa-i2svg] path').attr('d');

  const radius = (token.scaleHeight / 2) + token.strokeWidth / 2 ;
  const tokenCenterTop = token.top + radius;
  const tokenCenterLeft = token.left + radius;

  const indicatorAngle = initialConditionIndicatorAngle + (index * conditionIndicatorAngleStep)
  const offsetTopFromCenter = radius * Math.sin(indicatorAngle);
  const offsetLeftFromCenter = radius * Math.cos(indicatorAngle);

  const top = tokenCenterTop + offsetTopFromCenter;
  const left = tokenCenterLeft + offsetLeftFromCenter;

  const offsetTop = top - token.top;
  const offsetLeft = left - token.left;

  var indicatorObject = new fabric.Path(path, {
    offsetTop: offsetTop,
    offsetLeft: offsetLeft,
    top: top, 
    left: left, 
    fill: 'white',
    associatedToken: token,
    uid: uniqueId(),
    ...sharedIndicatorProperties
   });
   var backgroundObject = new fabric.Circle({
    offsetTop: offsetTop,
    offsetLeft: offsetLeft,
    top: top, 
    left: left, 
    strokeWidth: 1,
    radius: 10,
    fill: indicator.color,
    stroke: 'white',
    associatedToken: token,
    uid: uniqueId(),
    ...sharedIndicatorProperties
  });

   indicatorObject.scale(0.025);
   board.addObject(backgroundObject);
   board.addObject(indicatorObject);
   token.indicatorObjects.push(indicatorObject)
   token.indicatorObjects.push(backgroundObject)
 
}


export const resetConditionIndicatorObjects = function(token) {
  if(token.conditionIndicators != null) {
    if(token.indicatorObjects != null ) {
      token.indicatorObjects.forEach(indicatorObject => board.remove(indicatorObject))
    }
    token.indicatorObjects = [];
    var index = 0;
    token.conditionIndicators.reverse().forEach(function(indicator) {
      createConditionIndicatorObject(indicator, token, index);
      index = index + 1;
    });
  }
}

export const resetCombatantIndicatorObject = function(token) {
  if(!board.currentUserIsGm) { return }
  removeCombatIndicatorObjects(token, board);
  if(token.showGmCombatIndicator) {
    createCombatIndicatorObjects(token);
  }
}

