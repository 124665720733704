import Dropzone from "dropzone";
import { handleAjaxResponse } from "./handle_ajax_response";

$( document ).on('page_content_updated', function(event, newContent) {
  
  if ($(newContent).find('.dropzone-element').length > 0) {
    var ajaxResponse;
    const dropzoneForm = $('.dropzone-element:not(.dropzone-added) .dropzone-form')
    const dropzoneConfig = {
      url: dropzoneForm.attr('action'),
      paramName: dropzoneForm.find('input[type=file]').attr('name'),
      maxFiles: 20,
      headers: {
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content,
      },
      success: function(file, response) {
        ajaxResponse = response;  
      },
      disablePreviews: true,
      init: function () {
        let dz = this;
        dz.on("queuecomplete", function () {
          handleAjaxResponse(ajaxResponse, $(this));
        }),
        dz.on("addedfile", file => {
          $('.loading-indicator').removeClass('d-none');
        });
        dz.on("dragenter", function(event) {
          if (Array.from(event.dataTransfer.types).includes('Files')) {
            dz.element.classList.add("drag-over");
          }
        });
        dz.on("dragleave", function(event) {
          if (!dz.element.contains(event.relatedTarget)) {
            dz.element.classList.remove("drag-over");
          }
        });
        dz.on("dragover", function(event) {
          if (Array.from(event.dataTransfer.types).includes('Files')) {
            dz.element.classList.add("drag-over");
          }
        });
        dz.on("drop", function() {
          dz.element.classList.remove("drag-over");
          finishDragging();
        });
      }
    };
    new Dropzone(".dropzone-element:not(.dropzone-added)", dropzoneConfig);
    dropzoneForm.addClass('dropzone-added');
  }
});


// Function to create an overlay
function createOverlay() {
  
  return `
  <div class="dropzone-overlay d-flex">
    <div class='dropzone-overlay-text'>
      Drop Images Here
      <i class="fa-regular fa-share-all fa-rotate-90 mt-2"></i>
    </div>
  </div>
  `
}

// Add overlays to all Dropzone elements
function addOverlays() {
  const dropzones = document.querySelectorAll('.dropzone-element');
  dropzones.forEach(dropzone => {
    if (!dropzone.querySelector('.dropzone-overlay')) {
      const overlay = createOverlay();
      dropzone.insertAdjacentHTML('beforeend', overlay);
    }
  });
}

// Remove overlays from all Dropzone elements
function removeOverlays() {
  const overlays = document.querySelectorAll('.dropzone-overlay');
  overlays.forEach(overlay => {
      overlay.parentNode.removeChild(overlay); // Remove the overlay from its parent
  });
}

function draggingFile(event) {
  return Array.from(event.dataTransfer.types).includes('Files');
}

function draggingFromImageLibrary(event) {
  return Array.from(event.dataTransfer.types).includes('imagedragtype');
}

function draggingFromShapeLibrary(event) {
  return Array.from(event.dataTransfer.types).includes('shapedragtype');
}

// Listen for drag events on the window
window.addEventListener('dragover', function(event) {
  event.preventDefault(); // Necessary to allow dropping
  if (draggingFile(event)) {
    // dont trigger this if you're dragging something from inside a dropzone
    addOverlays();
  }
  if(draggingFile(event) || draggingFromImageLibrary(event) || draggingFromShapeLibrary(event)){
    const mainDiv = document.getElementById('grid');
    mainDiv.classList.add('file-dragging');
  }
});

window.addEventListener('dragleave', function(event) {
  event.preventDefault();
  if (event.relatedTarget == null) {
    removeOverlays();
    const mainDiv = document.getElementById('grid');
    mainDiv.classList.remove('file-dragging');
  }
});

window.addEventListener('drop', function(event) {
  event.preventDefault(); // need to prevent browsers from opening the file
  finishDragging();
});

export function finishDragging() {
  removeOverlays();
  const mainDiv = document.getElementById('grid');
  mainDiv.classList.remove('file-dragging');
}