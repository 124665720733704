import * as calculations from "./board_visibility_calculations"
import * as requests from "./board_requests"
import { linesForWallPoint } from "./board_walls"
import { conditionalUrlSlideBack } from "../navigation/slide_nav";

const wallLineEraseBuffer = 16;

var board;
$( document ).on('board:created', function(event, b) { board = b });

export function eraseOrphanedLines(object) {
  if(object.type != "circle" || object.wall == null) { return }
  const [wallsToMoveFirstPoint, wallsToMoveSecondPoint ] = linesForWallPoint(object);
  wallsToMoveFirstPoint.forEach(function(wall) {
    board.remove(wall); 
    requests.destroyRequest([wall.uid]);
  })
  wallsToMoveSecondPoint.forEach(function(wall) {
    board.remove(wall); 
    requests.destroyRequest([wall.uid]);
  })
}

const wallEraseBufferZone = function(x, y) {
  var halfBuffer = wallLineEraseBuffer / 2;
  return calculations.linesForSquare(x - halfBuffer, y - halfBuffer, x + halfBuffer, y + halfBuffer);
}

export const clickedWallLine = function(options) {
  var allWalls = board.getObjects().filter(object => object.wall == true && object.type == 'line' );
  const testingWalls = wallEraseBufferZone(options.absolutePointer.x, options.absolutePointer.y);
  var intersectingWall;
  testingWalls.forEach(function(testingWall) {
    allWalls.forEach(function(wall) {
      if(intersectingWall != null) { return }
      var intersection = calculations.linesIntersectAt(testingWall, wall)
      if(intersection != null) {
        intersectingWall = wall;
      }
    })
  })
  return intersectingWall;
}

export const eraseWallLine = function(options) {
  var intersectingWall = clickedWallLine(options);
  if (intersectingWall == null) { return false }

  // eraseOrphanedWallPoints(intersectingWall);
  board.removeObject(intersectingWall);
  requests.destroyRequest([intersectingWall.uid]);
  conditionalUrlSlideBack(board.urlForObject(intersectingWall));
  board.clickCanBeUndone('remove');
  return true
}