import consumer from "./consumer"
import { handleNewContentData } from "../custom/handle_ajax_response"

$( document ).on('user_channel_id_set', function(event, userId) {
  consumer.subscriptions.create({ 
    channel: "UserChannel",
    user_id: userId,
    }, {
    received(data) {
      if(data.user_board_updates != null) {
        var board = window.__board;
        board.set(data.user_board_updates);
      }
      handleNewContentData(data);
    }
  });
});
