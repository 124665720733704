import isMobile from "../lib/is_mobile"

function closeAll(target) {
  const expandable = target.closest('.expandable').parent();
  expandable.find('.details').collapse('hide');
  expandable.find('.label').collapse('show');
  expandable.find('.expandable').addClass(['action', 'cursor']);
  target.closest('.panel-list').parent().find('.all-closed-option').collapse('show');

}

// opening new forms
$(document).on('click','.expandable.action',function(event){
  closeAll($(event.target));
  $(this).closest('.panel-list').parent().find('> .all-closed-option').collapse('hide');
  $(this).find('.label').first().collapse('toggle');
  $(this).find('.details').first().collapse('toggle');
  $(this).removeClass(['action', 'cursor'])
  
  // focus on first input (for desktop)
  var input = $(this).find(':text:visible:not(.dont-focus)').first();
  if (!isMobile()) {
    setTimeout(function(){ 
      input.focus();

      var tmpStr = input.val();
      input.val('');
      input.val(tmpStr);
    }, 200);
  }
});

// closing forms
$(document).on('click','.expandable .cancel',function(event){
  closeAll($(event.target));
});
