function createRGBA(red, green, blue, alpha) {
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
}

$( document ).on('page_content_updated', function(event, newContent) {
  
  if ($(newContent).find('.color-select-component').length > 0) {
    // add color picker to any color fields
    var colorPickers = $('.slide-nav-page.active .color-select-component:not(.color-select-created)')
    if (colorPickers.length > 0) {
      colorPickers.addClass('color-select-created');
      const input = colorPickers.find('input');
      const alwan = new Alwan('.color-select', {color: input.val() });
      alwan.on('change', (ev) => {
        const newColor = createRGBA(ev.r, ev.g, ev.b, ev.a);
        input.val(newColor);
        input.parent().find('.color-select-sample').css('background-color', newColor);
        input.parent().find('.color-select-tag').css('color', newColor);
      });
      alwan.on('close', (ev) => {
        input.trigger('change');
      })
    }
  }
});
