var board;
var base = fabric.Canvas.prototype;

const highlightTimer = 4000;
const unlockAttemptTimer = 1000;
const rulerTimer = 0;

$( document ).on('board:created', function(event, b) { board = b; });

function fadeTimer(object) {
  if(object.unlockAttempt == true) { return unlockAttemptTimer }
  if(object.ruler == true) { return rulerTimer }
  return highlightTimer;
}

base.fadeRemove = function(object) {
  object.animate('opacity', '0', {
    duration: 1000,
    onChange: board.renderAll.bind(board),
    onComplete: function() {
      board.remove(object);
    }
  });
}

base.fadeRemoveTimer = function(object) {
  object.fadeTimeout = setTimeout(function() {
    board.fadeRemove(object)
  }, fadeTimer(object));
}


