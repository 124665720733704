import * as calculations from "./board_visibility_calculations"
import * as Comlink from 'comlink';
import VisibilityWorker from "./visibility.worker.js";
import uniqueId from "../../lib/unique_id.js";

var board;
var base = fabric.Canvas.prototype;
var workers = [];

var wallLines = [];
var wallPoints = [];

var workersSupported = typeof Worker !== 'undefined';
const turnOffWorkersTrigger = 300;
const turnOnWorkersTrigger = 500;

var emptyCircle = new fabric.Circle({
  radius: 0,
  top: 0,
  left: 0,
  absolutePositioned: true,
  inverted: true
});

$( document ).on('board:created', function(event, b) { board = b; });

const cornerWallPoints = function() {
  return [
    { left: 0, top: 0 }, 
    { left: 0, top: board.mapHeight }, 
    { left: board.mapWidth, top: 0 }, 
    { left: board.mapWidth, top: board.mapHeight }
  ]
}

const borderWallLines = function() {
  return calculations.linesForSquare(0,0, board.mapWidth, board.mapHeight);
}

const calculateWallData = async function() {
  const a = uniqueId()

  wallLines = [];
  wallPoints = [];

  board.getObjects().forEach(function(object) {
    if (object.wall == true) {
      if (object.type == 'circle') {
        var wallPoint = {
          left: object.left,
          top: object.top
        };
        wallPoints.push(wallPoint);
      } else if (object.type == 'line') {
        // do not count transparent and open doors as walls for visibility 
        if(object.transparent) { return }
        if(object.doorState == 'open') { return }
        var wallLine = {
          x1: object.x1,
          y1: object.y1,
          x2: object.x2,
          y2: object.y2
        };
        wallLines.push(wallLine);
      }
    }
  });
  board.wallPointsCount = wallPoints.length;
  wallLines = wallLines.concat(borderWallLines());
  wallPoints = wallPoints.concat(cornerWallPoints());
}

const visibilityBoundaries = async function(point) {  
  if (workersSupported && board.useWorkersForVisibility) {
    const worker = new VisibilityWorker();
    workers.push(worker);
    const workerInstance = Comlink.wrap(worker);
    return await workerInstance.pointsOfSightPolygon({point: point, wallLines: wallLines, wallPoints: wallPoints});
  } else {
    return calculations.pointsOfSightPolygon(point, wallLines, wallPoints);
  }
}

const restrictVisibileSectionByVisionAndLight = function(object, visionDistance, lightRanges, visibleSection) {
  if(visionDistance != null ) { 
    var sightLimit = new fabric.Circle({
      radius: visionDistance + (object.scaleHeight / 2), 
      top: object.top - visionDistance ,
      left: object.left - visionDistance ,
      absolutePositioned: true,
    });
    lightRanges.push(sightLimit);
  }
  var sightLimitGroup = new fabric.Group(lightRanges)
  sightLimitGroup.absolutePositioned = true;
  sightLimitGroup.objectCaching = false;
  visibleSection.clipPath = sightLimitGroup;
}


const calculateVisibleSectionForObject = async function(object) {
  var visibleSection;
  var polygons = []
  var sightPoints;
  if (workersSupported && board.useWorkersForVisibility) {
    const worker = new VisibilityWorker();
    workers.push(worker);
    const workerInstance = Comlink.wrap(worker);
    sightPoints = await workerInstance.objectSightLineStartPoints({object: {left: object.left, top: object.top, scaleWidth: object.scaleWidth, scaleHeight: object.scaleHeight}, wallLines: wallLines});
  } else {
    sightPoints = calculations.objectSightLineStartPoints(object, wallLines)
  }

  const sightLinePromises = sightPoints.map(async function(objectSightLineStartPoint) {
    const [points, sightLines] = await visibilityBoundaries(objectSightLineStartPoint); 
    var polygon = new fabric.Polygon(points)
    polygon.objectCaching = false;
    polygons.push(polygon);
    board.updateDebugVisibility(objectSightLineStartPoint, points, sightLines);
  });
  // Wait for all the promises to resolve
  await Promise.all(sightLinePromises);
  visibleSection = new fabric.Group(polygons);
  visibleSection.inverted = true;
  visibleSection.absolutePositioned = true;
  visibleSection.objectCaching = false;
  return visibleSection;
}

// This is the light area out to a certain radius, taking into account blocking walls
const newLightArea = async function(object, radius) {
  var left, top;
  if(object.token == true) {
    left = object.left + (object.scaleWidth / 2);
    top = object.top + (object.scaleHeight / 2);
  } else {
    left = object.left;
    top = object.top
  }
  const circle = new fabric.Circle({
    radius: radius,
    left: left,
    top: top,
    originX: 'center',
    originY: 'center',
    absolutePositioned: true,
  });

  // calculate the sightlines and points for the light source and clip it to the light radius
  const [points, _] = await visibilityBoundaries({x: left, y: top});
  const polygon = new fabric.Polygon(points);
  polygon.objectCaching = false;
  polygon.clipPath = circle;

  return polygon;
}

const allBrightLightRanges = async function() {
  const allLightSources =  board.getObjects().filter(object => object.brightLightRadius != null)

  const lightAreaPromises = allLightSources.map(async function(light) {
    return await newLightArea(light, light.brightLightRadius);
  });

  return await Promise.all(lightAreaPromises);
}

const allDimLightRanges = async function() {
  const allLightSources =  board.getObjects().filter(object => object.dimLightRadius != null)

  const lightAreaPromises = allLightSources.map(async function(light) {
    // Determine the light radius
    var lightRadius = light.dimLightRadius;
    if (light.brightLightRadius != null) {
      lightRadius = Math.max(light.brightLightRadius, light.dimLightRadius);
    }

    return await newLightArea(light, lightRadius);
  });

  return await Promise.all(lightAreaPromises);
}

const boardDoesNotNeedsVisibility = function() {
  return !board.restrictVisibility || (board.currentUserIsGm && !board.visibilityVisible)
}

const getDarknessLayer = function() {
  if(board.darknessLayer != null) {
    return board.darknessLayer
  } else if (board.gmDarknessLayer != null) {
    return board.gmDarknessLayer
  }
}
const updateVisibilityForSelectedToken = async function() {
  const token = board.selectedToken();
  const a = uniqueId();
  if (token == null) {
    if(!board.currentUserIsGm) {
      // setting the clip path to null doesn't work for some reason, so we need an empty circle
      if(getDarknessLayer() != null) {
        getDarknessLayer().clipPath = emptyCircle;
      }
      if(board.dimnessLayer != null) {
        board.dimnessLayer.clipPath = emptyCircle;
      }
    } else {
      // where you're the gm and have no token selected - just show lights
      if(getDarknessLayer() != null) {
        const dimLightRanges = await allDimLightRanges();
        var dimLightGroup = new fabric.Group(dimLightRanges);
        dimLightGroup.absolutePositioned = true;
        dimLightGroup.inverted = true;
        getDarknessLayer().clipPath = dimLightGroup;
      }
      if(board.dimnessLayer != null) {
        const brightLightRanges = await allBrightLightRanges();
        var brightLightGroup = new fabric.Group(brightLightRanges);
        brightLightGroup.absolutePositioned = true;
        brightLightGroup.inverted = true;
        board.dimnessLayer.clipPath = brightLightGroup;
      } 
    }
    board.renderAll();
  } else {
    const darknessVisibleSection = await calculateVisibleSectionForObject(token);
    
    if(board.lighting == 'dark') {
      // if in the dark, apply darkvision and all dim lights to darkness layer, apply
      // all bright lights to dimness layer, but do not apply darkvision to dimness layer
      const dimLightRanges = await allDimLightRanges();
      restrictVisibileSectionByVisionAndLight(token, token.darkvisionRadius, dimLightRanges, darknessVisibleSection);
      const dimnessVisibleSection = fabric.util.object.clone(darknessVisibleSection);
      const brightLightRanges = await allBrightLightRanges();
      restrictVisibileSectionByVisionAndLight(token, null, brightLightRanges, dimnessVisibleSection);
      board.dimnessLayer.clipPath = dimnessVisibleSection;
    } else if (board.lighting == 'dim') {
      // if in dim light, apply darkvision and all bright lights to dimness layer
      const dimnessVisibleSection = fabric.util.object.clone(darknessVisibleSection);
      const brightLightRanges = await allBrightLightRanges();
      restrictVisibileSectionByVisionAndLight(token, token.darkvisionRadius, brightLightRanges, dimnessVisibleSection);
      board.dimnessLayer.clipPath = dimnessVisibleSection;
    }

    getDarknessLayer().clipPath = darknessVisibleSection;
    board.renderAll();
  }
}

function terminateWorkersAndClearVariables() {
  workers.forEach(worker => worker.terminate());
  workers.length = 0; // Clear the array
  wallPoints = [];
  wallLines = [];
}

base.updateVisibility = async function() {
  const start = new Date().getTime();

  terminateWorkersAndClearVariables();
  board.clearDebugVisibility();
  if(boardDoesNotNeedsVisibility()) {
    board.removeVisibilityLayers();
  } else {
    await calculateWallData();
    board.addVisibilityLayers();
    await updateVisibilityForSelectedToken();
  }
  // the render all is needed otherwise there are very strange issues with drawing visibility only for regular players
  // not for gms
  board.requestRenderAll();
  terminateWorkersAndClearVariables();
  const duration = new Date().getTime() - start;

  if(duration < turnOffWorkersTrigger) {
    board.useWorkersForVisibility = false;
  } else if(duration > turnOnWorkersTrigger) {
    board.useWorkersForVisibility = true;
  }
}

