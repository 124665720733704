import { moveObjectsToLayers } from "./board_layers";
import * as requests from "./board_requests"

var board;
var base = fabric.Canvas.prototype;

$( document ).on('board:created', function(event, b) { board = b; });

base.selectedToken = function() {
  if (board.selectedCharacterId == null) { return }
  return board.findObject(board.selectedCharacterId);
}

base.tokenSelected = function() {
  return board.selectedToken() != null
}

base.highlightToken = function(token) {
  var shadow = new fabric.Shadow({ color: '#485fc7', blur: 20, affectStroke: false, nonScaling: true });
  token.set({shadow: shadow})
}

base.selectCharacter = function(id) {
  if(board.selectedCharacterId == id) { return }
  if(!board.isSelectableCharacterId(id)) { return }

  if(board.tokenSelected()) {
    board.selectedToken().set({ shadow: null });
  }

  board.selectedCharacterId = id;

  if(board.tokenSelected()) { 
    board.highlightToken(board.selectedToken());
  }
  moveObjectsToLayers();
  board.renderAll();
  board.updateVisibility();
}


base.selectCharacterOnMouseInteraction = function(options) {
  if(options.target == null) { return }
  if(options.target.token == null ) { return }
  if(board.selectedCharacterId == options.target.uid) { return }
  if(!board.isSelectableCharacterId(options.target.uid)) { return }


  board.selectCharacter(options.target.uid);
  requests.selectCharacter(board.selectedCharacterId)
}

base.isSelectableCharacterId = function(characterId) {
  return characterId == null || board.selectableCharacterIds == 'all' || board.selectableCharacterIds.includes(characterId)
}